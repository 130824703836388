@charset "UTF-8";
/* --------------------------------------------------------
    setting
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
/* --------------------------------------------------------
    mixin
-------------------------------------------------------- */
/* --------------------------------------------------------
    pulgins：jQueryで使用するcssなどを配置
-------------------------------------------------------- */
/* --------------------------------------------------------
    module：各ページ使用されるパーツ(ブログ記事、リストなど)
-------------------------------------------------------- */
.links[data-v-517e8db6] {
  margin-bottom: 51px;
}
@media screen and (max-width: 768px) {
.links[data-v-517e8db6] {
    margin-bottom: 3.91134vw;
}
}
.links ul[data-v-517e8db6] {
  display: flex;
  /* -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; */
	flex-flow: row nowrap;
}
@media screen and (max-width: 768px) {
.links ul[data-v-517e8db6] {
    flex-flow: row wrap;
}
}
.links .link[data-v-517e8db6] {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
}
.link._current[data-v-517e8db6] {
	flex-shrink: 1;
}
@media screen and (max-width: 768px) {
.links .link[data-v-517e8db6] {
    font-size: 3.12907vw;
    margin-bottom: 3.91134vw;
}
}
.links .link > a[data-v-517e8db6] {
  color: #19448e;
}
.links .link > a[data-v-517e8db6],
.links .link > p[data-v-517e8db6] {
  /* line-height: 1; */
  display: inline;
}
.links .link[data-v-517e8db6]:after {
  content: "/";
  margin: 0 1rem;
}
.links .link[data-v-517e8db6]:last-child:after {
  content: none;
}
.product[data-v-517e8db6] {
  padding-top: 50px;
  padding-bottom: 57px;
}
.product-summary[data-v-517e8db6] {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
.product-summary[data-v-517e8db6] {
    flex-flow: column-reverse;
    margin-bottom: 5.86701vw;
}
}
.product-head[data-v-517e8db6] {
  /* -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; */
	padding-right: 2vw;
	flex-basis: 50%;
}
.product-tit[data-v-517e8db6] {
  font-size: 30px;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.4;
  word-break: break-word;
}
@media screen and (max-width: 820px) {
.product-tit[data-v-517e8db6] {
    font-size: 3.5vw;
}
}
@media screen and (max-width: 768px) {
.product-tit[data-v-517e8db6] {
    font-size: 7.04042vw;
    margin-bottom: 2.60756vw;
}
}
.product-price[data-v-517e8db6] {
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  color: #cc1458;
  margin-bottom: 2rem;
}
.del[data-v-517e8db6]{
  text-decoration: line-through;
  color: #a5a5a5;
}
@media screen and (max-width: 820px) {
.product-price[data-v-517e8db6] {
    font-size: 3.7vw;
}
}
@media screen and (max-width: 768px) {
.product-price[data-v-517e8db6] {
    font-size: 7.43155vw;
    margin-bottom: 7.1708vw;
}
}
.product-list[data-v-517e8db6] {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.product-item[data-v-517e8db6] {
  display: flex;
  margin-bottom: 1rem;
}
@media screen and (max-width: 820px) {
.product-item[data-v-517e8db6] {
    flex-flow: column;
}
}
@media screen and (max-width: 768px) {
.product-item[data-v-517e8db6] {
    flex-flow: row;
}
}
.product-item > *[data-v-517e8db6] {
  line-height: 1.6;
  letter-spacing: 0;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
.product-item > *[data-v-517e8db6] {
    font-size: 3.65059vw;
}
}
.product-item:first-child .product-cnt[data-v-517e8db6] {
  font-weight: bold;
  color: #19448e;
}
.product-item ._head[data-v-517e8db6] {
  flex-shrink: 0;
}
.product-imgs[data-v-517e8db6] {
  display: flex;
}
@media screen and (max-width: 768px) {
.product-imgs[data-v-517e8db6] {
    margin-bottom: 7.1708vw;
    justify-content: space-between;
}
}
.product-mainimgs[data-v-517e8db6] {
  position: relative;
  width: 453px;
  height: 453px;
  flex-shrink: 0;
}
@media screen and (max-width: 1110px) {
.product-mainimgs[data-v-517e8db6] {
    width: 40vw;
    height: 40vw;
    /* margin-left: 4vw; */
}
}
@media screen and (max-width: 768px) {
.product-mainimgs[data-v-517e8db6] {
    width: 67.79661vw;
    height: 67.79661vw;
    /* margin-left: 0; */
}
}
.product-mainimg[data-v-517e8db6] {
  width: 453px;
  height: 453px;
  position: absolute;
}
@media screen and (max-width: 1110px) {
.product-mainimg[data-v-517e8db6] {
    width: 40vw;
    height: 40vw;
}
}
@media screen and (max-width: 768px) {
.product-mainimg[data-v-517e8db6] {
    width: 67.79661vw;
    height: 67.79661vw;
}
}
.product-mainimg[data-v-517e8db6]:not(._current) {
  display: none;
}
.product-mainimg[data-v-517e8db6]:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: 1%;
  right: 1%;
  background-image: url("../img/about/about-product_icon01.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
.product-mainimg[data-v-517e8db6]:after {
    width: 4.69361vw;
    height: 4.69361vw;
    background-size: contain;
    bottom: 2%;
    right: 2%;
}
}
.product-subimgs[data-v-517e8db6] {
  margin-left: 43px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 48px 48px;
      grid-template-columns: 48px 48px;
  -ms-grid-rows: (78px)[auto-fit];
      grid-template-rows: repeat(auto-fit, 78px);
}
@media screen and (max-width: 1110px) {
.product-subimgs[data-v-517e8db6] {
    margin-left: 2vw;
}
}
@media screen and (max-width: 980px) {
.product-subimgs[data-v-517e8db6] {
    -ms-grid-columns: 48px;
        grid-template-columns: 48px;
    -ms-grid-rows: (62px)[auto-fit];
        grid-template-rows: repeat(auto-fit, 62px);
}
}
@media screen and (max-width: 768px) {
.product-subimgs[data-v-517e8db6] {
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    -ms-grid-rows: (13.55932vw)[auto-fit];
        grid-template-rows: repeat(auto-fit, 13.55932vw);
}
}
.product-subimg[data-v-517e8db6] {
  width: 40px;
  height: 53px;
}
@media screen and (max-width: 980px) {
.product-subimg[data-v-517e8db6] {
    margin: 0 auto auto auto;
}
}
@media screen and (max-width: 768px) {
.product-subimg[data-v-517e8db6] {
    width: 8.21382vw;
    height: 10.95176vw;
}
}
.product-subimg._current[data-v-517e8db6] {
  border: 2px solid #ffa320;
}
.product-subimg[data-v-517e8db6]:nth-child(2n) {
  margin-right: 0;
  margin-left: auto;
}
@media screen and (max-width: 980px) {
.product-subimg[data-v-517e8db6]:nth-child(2n) {
    margin-right: auto;
}
}
.product-mainimg[data-v-517e8db6], .product-subimg[data-v-517e8db6] {
  cursor: pointer;
}
.product-mainimg img[data-v-517e8db6], .product-subimg img[data-v-517e8db6] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product-details[data-v-517e8db6] {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
.product-details[data-v-517e8db6] {
    font-size: 3.65059vw;
}
}
.product-subhead[data-v-517e8db6] {
  font-weight: 600;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.product-subcnt[data-v-517e8db6] {
  letter-spacing: 0;
}
.product-subcnt p[data-v-517e8db6] {
  white-space: pre-line;
}
.btns[data-v-517e8db6] {
  background-color: #f7f7f7;
  padding: 40px 0;
}
@media screen and (max-width: 768px) {
.btns[data-v-517e8db6] {
    padding: 6.77966vw 0;
}
}
.btns-list[data-v-517e8db6] {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
.btns-list[data-v-517e8db6] {
    flex-flow: column;
    align-items: center;
}
}
.btns-item[data-v-517e8db6]:not(:first-child) {
  margin-left: 75px;
}
@media screen and (max-width: 768px) {
.btns-item[data-v-517e8db6]:not(:first-child) {
    margin-left: 0;
}
.btns-item[data-v-517e8db6]:first-child {
    margin-bottom: 6.5189vw;
}
}
.btns-item:first-child .btn[data-v-517e8db6]:before {
  background-image: url("../img/common/common-icon_trhk.svg");
  width: 33px;
  height: 21px;
}
@media screen and (max-width: 768px) {
.btns-item:first-child .btn[data-v-517e8db6]:before {
    width: 5.60626vw;
    height: 3.52021vw;
}
}
.btns-item:last-child .btn[data-v-517e8db6]:before {
  background-image: url("../img/common/common-icon_favo.svg");
  width: 26px;
  height: 18px;
}
@media screen and (max-width: 768px) {
.btns-item:last-child .btn[data-v-517e8db6]:before {
    width: 5.3455vw;
    height: 3.65059vw;
}
}
@media screen and (max-width: 768px) {
.btns-item .btn._middle[data-v-517e8db6] {
    width: 84.74576vw;
    height: 13.55932vw;
}
}
.btn[data-v-517e8db6] {
  background-color: #19448e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  color: #fff;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.btn[data-v-517e8db6] {
    font-size: 3.65059vw;
}
}
.btn[data-v-517e8db6]:before {
  content: "";
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
.btn[data-v-517e8db6]:before {
    background-size: contain;
}
}
.btn._middle[data-v-517e8db6] {
  width: 285px;
  height: 56px;
}
.btn._small[data-v-517e8db6] {
  width: 165px;
  height: 39px;
}
@media screen and (max-width: 768px) {
.btn._small[data-v-517e8db6] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._small[data-v-517e8db6]:before {
  content: none;
}
.btn._bold[data-v-517e8db6] {
  width: 228px;
  height: 67px;
}
@media screen and (max-width: 768px) {
.btn._bold[data-v-517e8db6] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._large[data-v-517e8db6] {
  width: 854px;
  height: 67px;
}
@media screen and (max-width: 1080px) {
.btn._large[data-v-517e8db6] {
    width: 79vw;
}
}
@media screen and (max-width: 768px) {
.btn._large[data-v-517e8db6] {
    width: 84.74576vw;
    height: 11.73403vw;
}
}
.btn._sticky[data-v-517e8db6] {
  width: 230px;
  height: 37px;
  font-size: 14px;
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-517e8db6] {
    width: 20vw;
    font-size: 1.4vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-517e8db6] {
    width: 55.54107vw;
    height: 10.43025vw;
    font-size: 4.1721vw;
    margin-left: auto;
    margin-right: auto;
}
}
.btn._sticky[data-v-517e8db6]:before {
  width: 15px;
  height: 16px;
  background-image: url("../img/common/common-icon_post.svg");
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-517e8db6]:before {
    width: 1.5vw;
    height: 1.6vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-517e8db6]:before {
    width: 3.25945vw;
    height: 3.52021vw;
}
}
.btn._done[data-v-517e8db6]:before {
  background-image: url("../img/common/common-icon_deal.svg");
  width: 18px;
  height: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-517e8db6]:before {
    width: 3.65059vw;
    height: 2.86832vw;
}
}
.btn._message[data-v-517e8db6]:before {
  width: 22px;
  height: 14px;
  background-image: url("../img/common/common-icon_inquiry.svg");
}
@media screen and (max-width: 768px) {
.btn._message[data-v-517e8db6]:before {
    width: 4.69361vw;
    height: 2.9987vw;
}
}
.btn._message[data-v-517e8db6], .btn._done[data-v-517e8db6] {
  width: 153px;
  height: 44px;
  margin-top: 12px;
}
@media screen and (max-width: 768px) {
.btn._message[data-v-517e8db6], .btn._done[data-v-517e8db6] {
    width: 45.63233vw;
    height: 11.73403vw;
    margin-top: 3.91134vw;
}
}
.btn._done[data-v-517e8db6], .btn._message[data-v-517e8db6] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-517e8db6], .btn._message[data-v-517e8db6] {
    font-size: 3.65059vw;
}
}
.btn[data-v-517e8db6]:hover {
  opacity: 0.7;
}
.comment[data-v-517e8db6] {
  padding-top: 80px;
  padding-bottom: 200px;
}
@media screen and (max-width: 768px) {
.comment[data-v-517e8db6] {
    padding-top: 8.86571vw;
    padding-bottom: 20.33898vw;
}
}
.comment-tit[data-v-517e8db6] {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  font-weight: bold;
  font-family: source-han-sans-japanese,  sans-serif;
  line-height: 2;
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
.comment-tit[data-v-517e8db6] {
    font-size: 5.60626vw;
}
}
.comment-tit[data-v-517e8db6]:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 50px;
  background-color: #ffa320;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
@media screen and (max-width: 768px) {
.comment-tit[data-v-517e8db6]:after {
    width: 13.03781vw;
    height: 4px;
}
}
@media screen and (max-width: 410px) {
.comment-tit[data-v-517e8db6]:after {
    height: 2px;
}
}
.comment-list[data-v-517e8db6] {
  width: 100%;
  max-width: 975px;
  padding: 0 165px;
  margin: auto;
}
@media screen and (max-width: 1110px) {
.comment-list[data-v-517e8db6] {
    padding: 0 14vw;
}
}
@media screen and (max-width: 768px) {
.comment-list[data-v-517e8db6] {
    padding: 0 6.5189vw;
}
}
.comment-item[data-v-517e8db6] {
  margin-bottom: 37px;
}
@media screen and (max-width: 768px) {
.comment-item[data-v-517e8db6] {
    margin-bottom: 6.25815vw;
}
}
.comment-head[data-v-517e8db6] {
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
  color: #19448e;
  margin-bottom: 1rem;
  display: inline-flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.comment-head[data-v-517e8db6] {
    font-size: 3.12907vw;
}
}
.comment-head._seller[data-v-517e8db6]:after {
  content: "";
  display: block;
  width: 45px;
  height: 20px;
  background-image: url("../img/about/about-comment_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 0.5rem;
}
@media screen and (max-width: 768px) {
.comment-head._seller[data-v-517e8db6]:after {
    width: 11.47327vw;
    height: 5.21512vw;
    margin-left: 1.5rem;
}
}
.comment-cnt[data-v-517e8db6] {
  font-size: 16px;
  margin-bottom: 1rem;
  white-space: pre-line;
}
@media screen and (max-width: 768px) {
.comment-cnt[data-v-517e8db6] {
    font-size: 3.65059vw;
    margin-bottom: 3.25945vw;
}
}
.comment-date[data-v-517e8db6] {
  font-size: 12px;
  color: #7b6e77;
}
@media screen and (max-width: 768px) {
.comment-date[data-v-517e8db6] {
    font-size: 2.86832vw;
}
}
.comment-cntbox[data-v-517e8db6] {
  background-color: #f7f7f7;
  padding: 16px;
}
@media screen and (max-width: 768px) {
.comment-cntbox[data-v-517e8db6] {
    padding: 3.91134vw;
}
}
.comment-commit[data-v-517e8db6] {
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-flow: column;
}
@media screen and (max-width: 768px) {
.comment-commit[data-v-517e8db6] {
    font-size: 3.65059vw;
    margin-top: 5.21512vw;
}
}
.comment-commit .btn[data-v-517e8db6] {
  margin-top: 25px;
}
@media screen and (max-width: 768px) {
.comment-commit .btn[data-v-517e8db6] {
    margin-top: 5.21512vw;
}
}
.comment-commit #comment[data-v-517e8db6] {
  max-width: 645px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.comment-commit textarea[data-v-517e8db6] {
  width: 100%;
  height: 116px;
  border: 1px solid #ffa320;
  padding: 10px;
  font-size: 14px;
  /* Chrome */
  /* Firefox */
  /* IE */
}
@media screen and (max-width: 768px) {
.comment-commit textarea[data-v-517e8db6] {
    height: 31.81226vw;
    font-size: 2.86832vw;
    padding: 3.91134vw;
    width: 87%;
}
}
.comment-commit textarea[data-v-517e8db6] ::-webkit-input-placeholder {
  color: #7b6e77;
}
@media screen and (max-width: 768px) {
.comment-commit textarea[data-v-517e8db6] ::-webkit-input-placeholder {
    font-size: 2.86832vw;
}
}
.comment-commit textarea[data-v-517e8db6] ::-moz-placeholder {
  color: #7b6e77;
}
@media screen and (max-width: 768px) {
.comment-commit textarea[data-v-517e8db6] ::-moz-placeholder {
    font-size: 2.86832vw;
}
}
.comment-commit textarea[data-v-517e8db6] :-ms-input-placeholder {
  color: #7b6e77;
}
@media screen and (max-width: 768px) {
.comment-commit textarea[data-v-517e8db6] :-ms-input-placeholder {
    font-size: 2.86832vw;
}
}
.modal[data-v-517e8db6] {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
  display: block;
  overflow-y: hidden;
  z-index: -1;
}
.modal._active[data-v-517e8db6] {
  transition: all 0.4s;
  opacity: 1;
  visibility: visible;
  z-index: 30;
}
.modal-pgnav[data-v-517e8db6] {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 15px;
  height: 30px;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 2;
}
@media screen and (max-width: 768px) {
.modal-pgnav[data-v-517e8db6] {
    width: 3.91134vw;
    height: 7.56193vw;
    bottom: 10%;
}
}
.modal-pgnav._prev[data-v-517e8db6] {
  left: 30px;
}
@media screen and (max-width: 768px) {
.modal-pgnav._prev[data-v-517e8db6] {
    left: 5.21512vw;
}
}
.modal-pgnav._next[data-v-517e8db6] {
  right: 30px;
}
@media screen and (max-width: 768px) {
.modal-pgnav._next[data-v-517e8db6] {
    right: 5.21512vw;
}
}
@media screen and (max-width: 768px) {
.modal-pgnav img[data-v-517e8db6] {
    width: 100%;
}
}
.modal-close[data-v-517e8db6] {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  z-index: 2;
}
@media screen and (max-width: 768px) {
.modal-close[data-v-517e8db6] {
    width: 5.47588vw;
    height: 5.47588vw;
    top: 6.91004vw;
    right: 5.60626vw;
}
}
.modal-close[data-v-517e8db6]:before, .modal-close[data-v-517e8db6]:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 2px;
  margin: auto;
  background-color: #19448e;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
.modal-close[data-v-517e8db6]:before, .modal-close[data-v-517e8db6]:after {
    height: 4px;
}
}
@media screen and (max-width: 410px) {
.modal-close[data-v-517e8db6]:before, .modal-close[data-v-517e8db6]:after {
    height: 2px;
}
}
.modal-close[data-v-517e8db6]:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.modal-close[data-v-517e8db6]:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.modal-inner[data-v-517e8db6] {
  padding: 30px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fff;
  max-width: 90vw;
  margin: 5vh auto;
}
@media screen and (max-width: 768px) {
.modal-inner[data-v-517e8db6] {
    padding: 6.5189vw 0;
    max-height: 80vh;
}
}
.modal-mainimgs[data-v-517e8db6] {
  position: relative;
  width: 1055px;
  height: 74vh;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
.modal-mainimgs[data-v-517e8db6] {
    width: 74.05476vw;
    height: 98.56584vw;
    margin-top: 14.34159vw;
    margin-bottom: 19.55671vw;
}
}
.modal-mainimg[data-v-517e8db6] {
  width: 1055px;
  height: 74vh;
  position: absolute;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
.modal-mainimg[data-v-517e8db6] {
    width: 74.05476vw;
    height: 98.56584vw;
    margin: auto;
    top: 0;
    bottom: 0;
}
}
.modal-mainimg[data-v-517e8db6]:not(._current) {
  opacity: 0;
  visibility: hidden;
}
.modal-mainimg._current[data-v-517e8db6] {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.modal-subimgs[data-v-517e8db6] {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal-subimg[data-v-517e8db6] {
  width: 40px;
  height: 53px;
  margin-right: 18px;
}
@media screen and (max-width: 768px) {
.modal-subimg[data-v-517e8db6] {
    width: 8.21382vw;
    height: 10.95176vw;
    margin-right: 2.86832vw;
}
}
.modal-subimg._current[data-v-517e8db6] {
  border: 2px solid #ffa320;
}
.modal-subimg[data-v-517e8db6]:last-child {
  margin-right: 0;
}
.modal-mainimg[data-v-517e8db6], .modal-subimg[data-v-517e8db6] {
  cursor: pointer;
}
.modal-mainimg img[data-v-517e8db6], .modal-subimg img[data-v-517e8db6] {
  width: 100%;
  height: 100%;
  object-position: center;
}
.modal-mainimg img[data-v-517e8db6] {
  object-fit: scale-down;
}
.modal-subimg img[data-v-517e8db6] {
  object-fit: cover;
}

/* p.product-cnt {
    flex-shrink: 0;
} */

/* --------------------------------------------------------
    カード支払いOK_CSS追加
-------------------------------------------------------- */
.label_soldout[data-v-517e8db6]{
    font-size: 14px;
    background: var(--unnamed-color-cc1458) 0% 0% no-repeat padding-box;
	background: #CC1458 0% 0% no-repeat padding-box;
    color: #FFF;
    padding: 5px 25px;
    margin-left: 20px;
    vertical-align: middle;
}
.label_card[data-v-517e8db6]{
    font-size: 14px;
    border: 2px solid var(--unnamed-color-6dc040);
	background: #F6FFF2 0% 0% no-repeat padding-box;
	border: 2px solid #6DC040;
    color: #6DC040;
    padding: 5px 25px;
    margin-left: 20px;
    vertical-align: middle;
}
@media screen and (max-width: 768px) {
.label_soldout[data-v-517e8db6]{
		font-size: 2.8vw;
		padding: 2vw 4vw;
		margin-left: 6vw;
}
.label_card[data-v-517e8db6]{
		font-size: 2.8vw;
		padding: 2vw 4vw;
		margin-left: 6vw;
}
}
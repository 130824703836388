@charset "UTF-8";
/* --------------------------------------------------------
    setting：scss制御用の関数
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
/* --------------------------------------------------------
    mixin：アニメーション、レスポンシブの指定など
-------------------------------------------------------- */
/* --------------------------------------------------------
    layout：ヘッダー、フッター、メイン部分など全ページ共通のレイアウト
-------------------------------------------------------- */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
}

.header:before {
  content: "";
  display: block;
  width: 100%;
  height: 8px;
  position: absolute;
  top: 0;
  background-color: #19448e;
}

@media screen and (max-width: 768px) {
  .header:before {
    height: 1.56454vw;
  }
}

.header-inner {
  width: 100%;
  max-width: 1400px;
  height: 103px;
  margin: 0 auto;
  padding: 0 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header-inner {
    padding: 0 3.3vw;
  }
}

@media screen and (max-width: 768px) {
  .header-inner {
    height: 15.64537vw;
    padding: 1.56454vw 3.65059vw 0 3.65059vw;
  }
}

.header-logo {
  width: 240px;
  height: 48px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .header-logo {
    width: 23.4vw;
    height: 4.7vw;
  }
}

@media screen and (max-width: 768px) {
  .header-logo {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 1.56454vw;
  }
}

.header-logo a {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .header-logo a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .header-logo a img:first-child {
    width: 6.25815vw;
    height: 6.25815vw;
    margin-right: 1.30378vw;
  }
}

@media screen and (max-width: 768px) {
  .header-logo a img:last-child {
    width: 23.46806vw;
    height: 5.21512vw;
  }
}

.header-search {
  height: 35px;
}

@media screen and (min-width: 769px) {
  .header-search.pc {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .header-search {
    height: auto;
  }
}

.header-search input,
.header-search button {
  outline: none;
  border: none;
  -webkit-appearance: none;
}

.header-search input[type="search"] {
  width: 233px;
  height: 100%;
  padding-left: 12px;
  border-bottom: 1px solid #19448e;
  background-color: #f8f8f8;
  border-radius: 0;
}

@media screen and (max-width: 1024px) {
  .header-search input[type="search"] {
    width: 22.7vw;
  }
}

@media screen and (max-width: 768px) {
  .header-search input[type="search"] {
    width: 100%;
    border-top: 0 solid #19448e;
    border-bottom: 0 solid #19448e;
    background-color: #fff;
    padding: 0 4.56323vw;
    font-size: 3.12907vw;
  }
}

.header-search button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #19448e;
  cursor: pointer;
}

.header-search #search-toggle {
  display: none;
}

.header-search #header-searchsp,
.header-search #header-searchsp input[type="search"] {
  height: 0;
  opacity: 0;
  transition: all 0.5s;
}

.header-search #search-toggle:checked + #header-searchsp {
  height: auto;
  opacity: 1;
  transition: all 0.5s;
}

.header-search #search-toggle:checked + #header-searchsp input[type="search"] {
  opacity: 1;
  height: 10.95176vw;
  border-width: 2px;
  transition: all 0.5s;
}

@media screen and (max-width: 400px) {
  .header-search #search-toggle:checked + #header-searchsp input[type="search"] {
    border-width: 1px;
  }
}

.header-search ::-webkit-input-placeholder {
  font-size: 14px;
  color: #696969;
}

.header-search :-ms-input-placeholder {
  font-size: 14px;
  color: #696969;
}

.header-search ::placeholder {
  font-size: 14px;
  color: #696969;
}

@media screen and (max-width: 768px) {
  .header-search ::-webkit-input-placeholder {
    font-size: 3.12907vw;
  }
  .header-search :-ms-input-placeholder {
    font-size: 3.12907vw;
  }
  .header-search ::placeholder {
    font-size: 3.12907vw;
  }
}

.header-search ::-moz-placeholder {
  font-size: 14px;
  color: #696969;
}

@media screen and (max-width: 768px) {
  .header-search ::-moz-placeholder {
    font-size: 3.12907vw;
  }
}

.header-search :-moz-placeholder {
  font-size: 14px;
  color: #696969;
}

@media screen and (max-width: 768px) {
  .header-search :-moz-placeholder {
    font-size: 3.12907vw;
  }
}

.header-search ::-webkit-input-placeholder {
  font-size: 14px;
  color: #696969;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .header-search ::-webkit-input-placeholder {
    font-size: 3.12907vw;
  }
}

.header-search :-ms-input-placeholder {
  font-size: 14px;
  color: #696969;
}

@media screen and (max-width: 768px) {
  .header-search :-ms-input-placeholder {
    font-size: 3.12907vw;
  }
}

@media screen and (max-width: 768px) {
  .header-icon label {
    display: block;
    width: 8.99609vw;
    height: 10.16949vw;
  }
}

.header-icon label img {
  width: auto;
  height: 100%;
}

/** 211018 追記 **/
@media screen and (max-width: 768px) {
	.header-btns {
		display: flex;
		align-items: center;
	}
}
/** 211018 追記 **/

.header-btn {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-weight: bold;
  color: #19448e;
}

/** 211018 編集 **/
@media screen and (max-width: 768px) {
  .header-btn {
    position: relative;
	width: 10vw;
    height: 10vw;
  }
}
/** 211018 編集 **/

/** 211018 追記 **/
@media screen and (max-width: 768px) {
  .header-btn:after {
	width: 100% !important;
	height: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .header-btn > span {
	display: none !important;
  }
}
/** 211018 追記 **/

.header-btn:after {
  content: "";
  display: inline-block;
  background-size: contain;
  background-position: center;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .header-btn:after {
    margin-left: 0;
    position: absolute;
    right: 0;
    background-color: #fff;
  }
}

.header-btn:first-child:after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .header-btn:first-child:after {
    background-size: contain;
  }
}

.header-btn:last-child {
  margin-left: 45px;
}

@media screen and (max-width: 1024px) {
  .header-btn:last-child {
    margin-left: 3.8vw;
  }
}

@media screen and (max-width: 768px) {
  .header-btn:last-child {
    margin-left: 4vw;
  }
}

.header-btn:last-child:after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .header-btn:last-child:after {
    background-size: contain;
  }
}

.main {
  margin-top: 103px;
}

@media screen and (max-width: 768px) {
  .main {
    margin-top: 15.64537vw;
  }
}

.inner {
  width: 100%;
  max-width: 1400px;
  padding: 0 82px;
  margin: 0 auto;
}

@media screen and (max-width: 1366px) {
  .inner {
    padding: 0 6.00293vw;
  }
}

@media screen and (max-width: 768px) {
  .inner {
    padding: 0 6.5189vw;
  }
}

.footer {
  background-color: #19448e;
  color: #bfd7ff;
}

.footer .inner {
  height: 236px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .footer .inner {
    flex-flow: column;
    align-items: unset;
  }
}

@media screen and (max-width: 768px) {
  .footer .inner {
    height: auto;
    padding-bottom: 5.21512vw;
  }
}

.footer-cnts {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .footer-cnts {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .footer-cnts {
    flex-flow: column;
    align-items: flex-start;
    margin-top: 10.43025vw;
    margin-bottom: 5.21512vw;
  }
}

.footer-logo {
  margin-right: 45px;
}

@media screen and (max-width: 768px) {
  .footer-logo {
    margin-right: auto;
    margin-bottom: 5.21512vw;
    width: 29.46545vw;
    height: 6.64928vw;
  }
}

@media screen and (max-width: 768px) {
  .footer-logo img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .footer-cnt {
    padding-top: 6.5189vw;
    border-top: 2px solid #bfd7ff;
  }
}

@media screen and (max-width: 420px) {
  .footer-cnt {
    border-width: 1px;
  }
}

.footer-cnttit {
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .footer-cnttit {
    font-size: 3.12907vw;
    margin-bottom: 3.25945vw;
  }
}

.footer-links {
  height: 90px;
  display: flex;
  flex-flow: column wrap;
}

@media screen and (max-width: 768px) {
  .footer-links {
    height: auto;
    flex-flow: row wrap;
  }
}

.footer-link {
  margin-right: 60px;
  padding-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .footer-link {
    margin-right: 0;
    padding-bottom: 4.95437vw;
    width: 50%;
  }
}

.footer-link > a {
  color: #fff;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .footer-link > a {
    font-size: 2.60756vw;
  }
}

.footer .copy {
  font-size: 12px;
  letter-spacing: 0;
}

@media screen and (max-width: 1024px) {
  .footer .copy {
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footer .copy {
    font-size: 2.60756vw;
    text-align: left;
  }
}

/* --------------------------------------------------------
    base：リセットCSS、共通CSSなど
-------------------------------------------------------- */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
textarea {
  vertical-align: middle;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: normal;
}

/* --------------------------------------------------------
    ベース設定
-------------------------------------------------------- */
*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  opacity: 0.7;
  transition: 0.5s;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-position: from-font;
  text-underline-offset: 0.1em;
}

html {
  font-size: 10px;
}

body {
  color: #170613;
  font-weight: normal;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
  line-height: 1;
  background-color: white;
  overflow-x: hidden;
  letter-spacing: -0.025em;
}

body.scrollstop {
  overflow-y: hidden;
  position: relative;
}

body.scrollstop:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 8;
}

body.scrollstop .chat-footer {
  z-index: 1 !important;
  pointer-events: none !important;
}

p {
  line-height: 1.8;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

@media screen and (min-width: 769px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
}

input,
button {
  -webkit-appearance: none;
          appearance: none;
}

.__common_error_msg {
  font-size: 16px;
  font-weight: 600;
  font-weight: bold;
  color: #cc1458;
  margin: 1rem 10%;
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .__common_error_msg {
    font-size: 3.12907vw;
    margin: 1.5rem 5%;
    margin-left: 0;
  }
}

.error .inner {
  min-height: 80vh;
  padding-top: 125px;
  padding-bottom: 200px;
}

@media screen and (max-width: 768px) {
  .error .inner {
    padding-top: 10.43025vw;
    padding-bottom: 20.59974vw;
    min-height: 65vh;
  }
}

.error-tit {
  font-size: 76px;
  font-weight: bold;
  font-family: source-han-sans-japanese,  sans-serif;
  letter-spacing: 0.025em;
  margin-bottom: 73px;
}

@media screen and (max-width: 768px) {
  .error-tit {
    font-size: 11.73403vw;
    margin-bottom: 16.94915vw;
  }
}

.error-txt {
  font-size: 16px;
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .error-txt {
    font-size: 3.65059vw;
    margin-bottom: 20.8605vw;
  }
}

.error-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 318px;
  height: 67px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  background-color: #19448e;
}

@media screen and (max-width: 768px) {
  .error-btn {
    font-size: 3.65059vw;
    width: 100%;
    height: 11.73403vw;
  }
}

.error-btn:hover {
  text-decoration: none;
}

/**
20220315以降_画面デザイン調整
*/
.btn-inquiry{
	text-align: right;
}

.btn-inquiry a{
	font-size: 16px;
	color: #19448E;
	position: relative;
	font-weight: bold;
  margin-top: 20px;
}

.btn-inquiry a:before{
	position: absolute;
	display: block;
	content: "";
	background-image: url(../img/common/common-icon_inquiry.png);
	width: 21px;
	height: 20px;
	left: -24px;
	top: -2px;
	background-size: cover;
	background-repeat: no-repeat;
}

.link-btn {
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
}
@charset "UTF-8";
/* --------------------------------------------------------
    setting
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
/* --------------------------------------------------------
    mixin
-------------------------------------------------------- */
/* --------------------------------------------------------
    pulgins：jQueryで使用するcssなどを配置
-------------------------------------------------------- */
/* --------------------------------------------------------
    module：各ページ使用されるパーツ(ブログ記事、リストなど)
-------------------------------------------------------- */
.header-btn[data-v-e53458fd]:first-child:after {
  width: 21px;
  height: 20px;
  background-image: url("../img/common/common-icon_logout.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-e53458fd]:first-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_logout_sp.svg");
}
}
.header-btn[data-v-e53458fd]:last-child:after {
  width: 17px;
  height: 20px;
  background-image: url("../img/common/common-icon_mypage.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-e53458fd]:last-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_mypage_sp.svg");
}
}
.btn[data-v-e53458fd] {
  background-color: #19448e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  color: #fff;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.btn[data-v-e53458fd] {
    font-size: 3.65059vw;
}
}
.btn[data-v-e53458fd]:before {
  content: "";
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
.btn[data-v-e53458fd]:before {
    background-size: contain;
}
}
.btn._middle[data-v-e53458fd] {
  width: 285px;
  height: 56px;
}
.btn._small[data-v-e53458fd] {
  width: 165px;
  height: 39px;
}
@media screen and (max-width: 768px) {
.btn._small[data-v-e53458fd] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._small[data-v-e53458fd]:before {
  content: none;
}
.btn._bold[data-v-e53458fd] {
  width: 228px;
  height: 67px;
}
@media screen and (max-width: 768px) {
.btn._bold[data-v-e53458fd] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._large[data-v-e53458fd] {
  width: 854px;
  height: 67px;
}
@media screen and (max-width: 1080px) {
.btn._large[data-v-e53458fd] {
    width: 79vw;
}
}
@media screen and (max-width: 768px) {
.btn._large[data-v-e53458fd] {
    width: 84.74576vw;
    height: 11.73403vw;
}
}
.btn._sticky[data-v-e53458fd] {
  width: 230px;
  height: 37px;
  font-size: 14px;
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-e53458fd] {
    width: 20vw;
    font-size: 1.4vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-e53458fd] {
    width: 55.54107vw;
    height: 10.43025vw;
    font-size: 4.1721vw;
    margin-left: auto;
    margin-right: auto;
}
}
.btn._sticky[data-v-e53458fd]:before {
  width: 15px;
  height: 16px;
  background-image: url("../img/common/common-icon_post.svg");
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-e53458fd]:before {
    width: 1.5vw;
    height: 1.6vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-e53458fd]:before {
    width: 3.25945vw;
    height: 3.52021vw;
}
}
.btn._done[data-v-e53458fd]:before {
  background-image: url("../img/common/common-icon_deal.svg");
  width: 18px;
  height: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-e53458fd]:before {
    width: 3.65059vw;
    height: 2.86832vw;
}
}
.btn._message[data-v-e53458fd]:before {
  width: 22px;
  height: 14px;
  background-image: url("../img/common/common-icon_inquiry.svg");
}
@media screen and (max-width: 768px) {
.btn._message[data-v-e53458fd]:before {
    width: 4.69361vw;
    height: 2.9987vw;
}
}
.btn._message[data-v-e53458fd], .btn._done[data-v-e53458fd] {
  width: 153px;
  height: 44px;
  margin-top: 12px;
}
@media screen and (max-width: 768px) {
.btn._message[data-v-e53458fd], .btn._done[data-v-e53458fd] {
    width: 45.63233vw;
    height: 11.73403vw;
    margin-top: 3.91134vw;
}
}
.btn._done[data-v-e53458fd], .btn._message[data-v-e53458fd] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-e53458fd], .btn._message[data-v-e53458fd] {
    font-size: 3.65059vw;
}
}
.btn[data-v-e53458fd]:hover {
  opacity: 0.7;
}
.links[data-v-e53458fd] {
  margin-bottom: 51px;
}
@media screen and (max-width: 768px) {
.links[data-v-e53458fd] {
    margin-bottom: 3.91134vw;
}
}
.links ul[data-v-e53458fd] {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
.links ul[data-v-e53458fd] {
    flex-flow: row wrap;
			flex-flow: nowrap;
}
}
.links .link[data-v-e53458fd] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.links .link[data-v-e53458fd] {
    font-size: 3.12907vw;
    margin-bottom: 3.91134vw;
}
}
.links .link > a[data-v-e53458fd] {
  color: #19448e;
}

/* .links .link > a,
.links .link > p {
  line-height: 1;
} */
.links .link[data-v-e53458fd]:after {
  content: "/";
  margin: 0 1rem;
}
.links .link[data-v-e53458fd]:last-child:after {
  content: none;
}
.sidebar[data-v-e53458fd] {
  background-color: #f7f7f7;
  /* min-height: 1240px; */
  min-height: 110vh;
}
@media screen and (max-width: 768px) {
.sidebar[data-v-e53458fd] {
    position: fixed;
    top: 15.64537vw;
    right: 0;
    bottom: 0;
    z-index: 30;
    width: 72vw;
    min-height: auto;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    visibility: hidden;
    transition: all 0.5s ease;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
}
.sidebar[data-v-e53458fd] ::-webkit-scrollbar {
  display: none;
}
.sidebar._open[data-v-e53458fd] {
  transition: all 0.5s ease;
  visibility: visible;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.sidebar-inner[data-v-e53458fd] {
  margin-top: 45px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
.sidebar-inner[data-v-e53458fd] {
    margin-top: 9.77836vw;
    margin-bottom: 23.46806vw;
}
}
.sidebar-top[data-v-e53458fd] {
  padding: 0 10px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
.sidebar-top[data-v-e53458fd] {
    padding: 0 3.91134vw;
    margin-bottom: 6.5189vw;
}
}
.sidebar-top .btn._sticky[data-v-e53458fd] {
  margin-left: 0;
}
.sidebar-usrname[data-v-e53458fd] {
  font-size: 16px;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
.sidebar-usrname[data-v-e53458fd] {
    font-size: 3.65059vw;
    margin-bottom: 2.60756vw;
}
}
.sidebar-link[data-v-e53458fd] {
  border-top: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
  margin-top: -1px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
.sidebar-link[data-v-e53458fd] {
    font-size: 3.25945vw;
}
}
.sidebar-link > a[data-v-e53458fd] {
  padding: 20px 10px;
  display: block;
}
@media screen and (max-width: 768px) {
.sidebar-link > a[data-v-e53458fd] {
    padding: 4.56323vw;
}
}
.sidebar-link > a[data-v-e53458fd]:hover {
  text-decoration: none;
  opacity: 1;
}
.sidebar-link._current[data-v-e53458fd] {
  background-color: #fff;
  border-color: #fff;
  z-index: 2;
}
@media screen and (max-width: 768px) {
.sidebar-link._current[data-v-e53458fd] {
    background-color: #f7f7f7;
    border-color: #a0a0a0;
}
}
.sidebar-link._current > a[data-v-e53458fd] {
  color: #170613;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
.sidebar-link._current > a[data-v-e53458fd] {
    color: #19448e;
}
}
.sidebar-link:not(._current) > a[data-v-e53458fd] {
  color: #19448e;
}
.sidebar-link[data-v-e53458fd]:not(._current):hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.sidebar-link._logout a[data-v-e53458fd] {
  color: #ffa320;
  display: flex;
  align-items: center;
}
.sidebar-link._logout a[data-v-e53458fd]:before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 1rem;
  width: 3.25945vw;
  height: 3.12907vw;
  background-image: url("../img/common/common-icon_logout.svg");
}
#sidebarCloser[data-v-e53458fd] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  opacity: 0;
  display: none;
}
#sidebarCloser._show[data-v-e53458fd] {
  display: block;
}
.label[data-v-e53458fd] {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 38px 0;
  border-bottom: 1px solid #a0a0a0;
}
@media screen and (max-width: 768px) {
.label[data-v-e53458fd] {
    padding: 5.86701vw 0 4.95437vw 0;
    flex-flow: column;
    align-items: flex-start;
    font-size: 3.12907vw;
}
}
@media screen and (max-width: 768px) {
.label-txt[data-v-e53458fd] {
    margin-bottom: 2.60756vw;
}
}
.label-list[data-v-e53458fd] {
  display: flex;
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
.label-list[data-v-e53458fd] {
    margin-left: auto;
    margin-right: auto;
}
}
.label-item[data-v-e53458fd] {
  font-weight: 600;
  font-weight: bold;
}
.label-item[data-v-e53458fd]:first-child {
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
.label-item[data-v-e53458fd]:first-child {
    margin-right: 1.30378vw;
}
}
.label-item a[data-v-e53458fd] {
  width: 126px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.label-item a[data-v-e53458fd] {
    width: 41.72099vw;
    height: 9.38722vw;
}
}
@media screen and (max-width: 400px) {
.label-item a[data-v-e53458fd] {
    border-width: 1px;
}
}
.label-item a[data-v-e53458fd]:hover {
  text-decoration: none;
}
.label-item.dealing a[data-v-e53458fd] {
  color: #00bf28;
  border-color: #00bf28;
}
.label-item.done a[data-v-e53458fd] {
  color: #cc1458;
  border-color: #cc1458;
}
.label-item._active[data-v-e53458fd] {
  pointer-events: none;
}
.label-item._active.dealing a[data-v-e53458fd], .label-item._active.done a[data-v-e53458fd] {
  color: #fff;
}
.label-item._active.dealing a[data-v-e53458fd] {
  background-color: #00bf28;
}
.label-item._active.done a[data-v-e53458fd] {
  background-color: #cc1458;
}
.post[data-v-e53458fd] {
  padding: 30px 0 55px 0;
}
@media screen and (max-width: 768px) {
.post[data-v-e53458fd] {
    padding: 5.86701vw 0 16.94915vw 0;
}
}
.post textarea[data-v-e53458fd] {
  width: 100%;
  border: 1px solid #ffa320;
  border-radius: 0;
  margin-bottom: 25px;
  padding: 15px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.post textarea[data-v-e53458fd] {
    font-size: 2.86832vw;
    padding: 2.60756vw;
    margin-bottom: 6.5189vw;
    min-height: 32.59452vw;
}
}
.post textarea[data-v-e53458fd]:focus {
  outline: none;
}
.post-item[data-v-e53458fd] {
  display: flex;
  justify-content: center;
}
.drawer[data-v-e53458fd] {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 768px) {
.drawer[data-v-e53458fd] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(15.6vw - 1.6vw);
    height: calc(15.6vw - 1.6vw);
    background-color: #fff;
}
}
#drawer-checkbox[data-v-e53458fd] {
  display: none;
}
#drawer-icon[data-v-e53458fd] {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 55;
  width: 100%;
  height: 100%;
}
#drawer-icon span[data-v-e53458fd] {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  width: 5.86701vw;
  height: 3px;
  background-color: #19448e;
}
#drawer-icon span[data-v-e53458fd]::before,
#drawer-icon span[data-v-e53458fd]::after {
  -webkit-transform: rotate(0);
  background: #19448e;
  content: "";
  display: block;
  height: 3px;
  margin: auto;
  position: absolute;
  transform: rotate(0);
  transition: all 0.3s ease-in-out;
  width: 100%;
}
#drawer-icon span[data-v-e53458fd]::before {
  top: -1.95567vw;
}
#drawer-icon span[data-v-e53458fd]::after {
  bottom: -1.95567vw;
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd] {
  background: rgba(255, 255, 255, 0);
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::before,
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::after {
  content: "";
  display: block;
  height: 4px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 599px) {
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::before,
  #drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::after {
    height: 2px;
}
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-e53458fd]::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.chat[data-v-e53458fd] {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat-label[data-v-e53458fd] {
  font-size: 10px;
  padding: 5px;
  color: #fff;
}
@media screen and (max-width: 768px) {
.chat-label[data-v-e53458fd] {
    flex-shrink: 0;
    font-size: 2.60756vw;
    padding: 1.04302vw;
    margin-top: 0.7em;
}
}
.chat-label.dealing[data-v-e53458fd] {
  background-color: #00bf28;
}
.chat-label.done[data-v-e53458fd] {
  background-color: #cc1458;
}

/* .chat-list {
  margin-bottom: 600px;
}

@media screen and (max-width: 768px) {
  .chat-list {
    margin-bottom: 87.35332vw;
  }
} */
.chat-item[data-v-e53458fd] {
  margin-bottom: 35px;
  max-width: 80%;
}
@media screen and (max-width: 1200px) {
.chat-item[data-v-e53458fd] {
    max-width: 90%;
}
}
@media screen and (max-width: 980px) {
.chat-item[data-v-e53458fd] {
    max-width: 97%;
}
}
@media screen and (max-width: 768px) {
.chat-item[data-v-e53458fd] {
    max-width: 100%;
}
}
@media screen and (max-width: 768px) {
.chat-item[data-v-e53458fd] {
    margin-bottom: 6.25815vw;
}
}
.chat-item._opponent .chat-username a[data-v-e53458fd] {
  color: #19448e;
}
.chat-item._opponent .chat-cnts[data-v-e53458fd] {
  background-color: #e6efff;
}
.chat-item:not(._opponent) .chat-cnts[data-v-e53458fd] {
  background-color: #f7f7f7;
}
.chat-username[data-v-e53458fd] {
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.chat-username[data-v-e53458fd] {
    font-size: 3.12907vw;
}
}
.chat-post[data-v-e53458fd] {
  font-size: 12px;
  color: #7b6e77;
}
@media screen and (max-width: 768px) {
.chat-post[data-v-e53458fd] {
    font-size: 2.86832vw;
}
}
.chat-username[data-v-e53458fd], .chat-cnt[data-v-e53458fd] {
  margin-bottom: 1rem;
}
.chat-cnts[data-v-e53458fd] {
  padding: 15px;
}
@media screen and (max-width: 768px) {
.chat-cnts[data-v-e53458fd] {
    padding: 3.91134vw;
}
}
.chat-cnt[data-v-e53458fd] {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  white-space: pre-line;
}
@media screen and (max-width: 768px) {
.chat-cnt[data-v-e53458fd] {
    font-size: 3.38983vw;
}
}
.chat-footer[data-v-e53458fd] {
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  z-index: 3;
  background-color: #fff;
  padding-bottom: 20px;
}
.chat-footer[data-v-e53458fd]:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #d0d0d0;
  top: 0;
  right: calc(50% - 50vw);
  left: calc(50% - 50vw);
  z-index: -1;
}
@media screen and (max-width: 768px) {
.chat-footer[data-v-e53458fd]:after {
    height: 2px;
}
}
@media screen and (max-width: 400px) {
.chat-footer[data-v-e53458fd]:after {
    height: 1px;
}
}

/* --------------------------------------------------------
    page：各モジュールの位置の指定、ページごとにパーツのサイズが違う時など
-------------------------------------------------------- */
.main[data-v-e53458fd] {
  display: flex;
}
.main .sidebar[data-v-e53458fd] {
  position: relative;
  z-index: 9;
}
@media screen and (max-width: 768px) {
.main .sidebar[data-v-e53458fd] {
    position: fixed;
    z-index: 30;
}
}
.main .workspace[data-v-e53458fd] {
  width: 100%;
  overflow-x: hidden;
}
.main .workspace-inner[data-v-e53458fd] {
  height: 100%;
  padding: 0 50px;
}
@media screen and (max-width: 1081px) {
.main .workspace-inner[data-v-e53458fd] {
    padding: 0 2.5vw;
}
}
@media screen and (max-width: 768px) {
.main .workspace-inner[data-v-e53458fd] {
    padding: 0 6.5189vw;
}
}
.main .workspace-cnts[data-v-e53458fd] {
  background-color: #f7f7f7;
}
.main .workspace-tit[data-v-e53458fd] {
  font-size: 24px;
  line-height: 1.5;
  font-family: source-han-sans-japanese,  sans-serif;
  font-weight: 600;
  font-weight: bold;
  border-bottom: 1px solid #ffa320;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.main .workspace-tit[data-v-e53458fd] {
    font-size: 6.25815vw;
    margin-bottom: 9.12647vw;
    border-width: 2px;
    padding-bottom: 3.25945vw;
    line-height: 1.3;
    align-items: flex-start;
}
}
@media screen and (max-width: 400px) {
.main .workspace-tit[data-v-e53458fd] {
    border-width: 1px;
}
}
.main .links[data-v-e53458fd] {
  padding-top: 45px;
}
span.new-lavel[data-v-e53458fd] {
    font-family: 'source-han-sans-japanese';
    font-size: 14px;
    margin-left: 0.7rem;
    font-weight: 600;
    color: #cc1658;
}
@media screen and (max-width: 768px) {
span.new-lavel[data-v-e53458fd] {
    font-size: 3.38983vw;
    margin-left: 0.5rem;
}
}

/* 現金で支払う、カードで支払うのボタンデザイン調整 */
.pay_form_area[data-v-e53458fd]{
	display: flex;
	justify-content: center;
	align-items: center;
}
.pay_form_area button[data-v-e53458fd]{
	margin: 0 10px;
}
.pay_form_area .btn._small.pay[data-v-e53458fd]{
	width: 285px;
	height: 55px;
	position: relative;
}
.pay_form_area .btn._small.pay._card[data-v-e53458fd]::before,
.pay_form_area .btn._small.pay._cash[data-v-e53458fd]::before{
	  content: "";
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 1rem;
}
.pay_form_area .btn._small.pay._cash[data-v-e53458fd]::before{
		width: 30px;
		height: 21px;
		background-image: url(../img/common/common-icon_pay_cash.svg);
}
.pay_form_area .btn._small.pay._card[data-v-e53458fd]::before{
		width: 30px;
		height: 21px;
		background-image: url(../img/common/common-icon_pay_card.svg);
}
@media screen and (max-width: 768px) {
.pay_form_area[data-v-e53458fd]{
		flex-wrap: wrap;
}
.pay_form_area .btn._small.pay[data-v-e53458fd]{
		width: 100%;
    height: 13.333vw;
		position: relative;
		margin-bottom: 5.333vw;
}
.pay_form_area .btn._small.pay._cash[data-v-e53458fd]::before{
		width: 5.2vw;
		height: 3.6vw;
}
.pay_form_area .btn._small.pay._card[data-v-e53458fd]::before{
			width: 3.967vw;
			height: 2.8vw;
}
}

/**
20220315以降_画面デザイン調整
*/
@media screen and (max-width: 768px) {
.post[data-v-e53458fd] {
    padding: 5.86701vw 0 5.86701vw 0;
}
.chat-list[data-v-e53458fd]{
		margin-bottom: 100px;
}
.chat-footer[data-v-e53458fd] {
		position: static;
}
}
.announce_area[data-v-e53458fd]{
	text-align: center;
	max-width: 80%;
	border-top: 1px solid #7B6E77;
	border-bottom: 1px solid #7B6E77;
	margin-bottom: 15px;
}
.announce_message[data-v-e53458fd]{
	display: block;
	font-size: 16px;
	color: #7B6E77;
	padding: 15px 5px;
}
@media screen and (max-width: 768px) {
.announce_area[data-v-e53458fd]{
		max-width: 100%;
}
.announce_message[data-v-e53458fd]{
		padding: 10px 10px;
}
}
.payment_area[data-v-e53458fd] {
  border: 2px solid #19448e;
  padding: 17px 20px 14px;
  margin-top: 25px;
  width: 100%;
  font-size: 16px;
  color: #19448e;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.payment_area[data-v-e53458fd]:first-of-type {
  margin-top: 0;
}
.form-unit[data-v-e53458fd] {
	display: flex;
	align-items: center;
}
.form-unit[data-v-e53458fd]:before {
	content: '';
	width: 18px;
	height: 18px;
	display: inline-block;
	border: 1px solid #19448e;
	margin-right: 12px;
}
@media screen and (max-width: 768px) {
.form-unit[data-v-e53458fd]:before {
		width: 3.6vw;
		height: 3.6vw;
		margin-right: 2.5vw;
}
}
.payment[data-v-e53458fd] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
.payment[data-v-e53458fd] {
    width: 100%;
}
}
.choices[data-v-e53458fd] {
  margin-bottom: 25px;
  width: 100%;
}
.coupon_area[data-v-e53458fd] {
  display: none;
  margin-top: 17px;
  padding-left: 33px;
}
.btn._middle.payment[data-v-e53458fd]:disabled {
  background-color: #9d9d9d;
}
@media screen and (max-width: 768px) {
.btn._middle.payment[data-v-e53458fd] {
    width: 100%;
}
}
.w-small[data-v-e53458fd] {
  border: 1px solid #19448e;
	border-radius: 0;
	padding-left: 1em;
	padding-right: 1em;
  font-size: 14px;
  min-height: 39px;
  width: 60%;
}
@media screen and (max-width: 768px) {
.w-small[data-v-e53458fd] {
    width: 100%;
}
}
.btn._small.coupon[data-v-e53458fd] {
  background-color: #E3EDFF;
  border: 2px solid #19448e;
  margin-left: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 13px 27px;
  color: #19448e;
}
@media screen and (max-width: 768px) {
.btn._small.coupon[data-v-e53458fd] {
    margin-top: 12px;
    border: 1px solid #19448e;
    min-width: 100px;
}
}
input[type='checkbox']:checked + .form-unit[data-v-e53458fd]:before,
input[type='radio']:checked + .form-unit[data-v-e53458fd]:before {
	background-image: url(../img/common/common-icon_check.svg);
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
}
.comment-tit[data-v-e53458fd] {
  display: flex;
  font-size: 24px;
  font-weight: 700;
  font-weight: bold;
  font-family: source-han-sans-japanese,  sans-serif;
  line-height: 2;
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
.comment-tit[data-v-e53458fd] {
    font-size: 5.60626vw;
}
}
.comment-tit[data-v-e53458fd]:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 50px;
  background-color: #ffa320;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
@media screen and (max-width: 768px) {
.comment-tit[data-v-e53458fd]:after {
    width: 13.03781vw;
    height: 4px;
}
}
@media screen and (max-width: 410px) {
.comment-tit[data-v-e53458fd]:after {
    height: 2px;
}
}
.payment_area input[type="radio"]:checked ~ .coupon_area[data-v-e53458fd] {
  display: flex;
}
@media screen and (max-width: 768px) {
.payment_area input[type="radio"]:checked ~ .coupon_area[data-v-e53458fd] {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
}
}
.modal[data-v-e53458fd] {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
  display: block;
  overflow-y: hidden;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal._active[data-v-e53458fd] {
  transition: all 0.4s;
  opacity: 1;
  visibility: visible;
  z-index: 30;
}
.modal-inner[data-v-e53458fd] {
  padding: 30px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fff;
  width: 400px;
  margin: 5vh auto;
}
@media screen and (max-width: 768px) {
.modal-inner[data-v-e53458fd] {
    padding: 6.5189vw 0;
    max-height: 80vh;
    max-width: 90vw;
}
}
.btn_text[data-v-e53458fd] {
  color: #19448e;
}
@charset "UTF-8";
/* --------------------------------------------------------
    setting
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
/* --------------------------------------------------------
    mixin
-------------------------------------------------------- */
/* --------------------------------------------------------
    pulgins：jQueryで使用するcssなどを配置
-------------------------------------------------------- */
/* --------------------------------------------------------
    module：各ページ使用されるパーツ(ブログ記事、リストなど)
-------------------------------------------------------- */
.header-btn[data-v-61680f5f]:first-child:after {
  width: 17px;
  height: 20px;
  background-image: url("../img/common/common-icon_mypage.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-61680f5f]:first-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_mypage_sp.svg");
}
}
.header-btn[data-v-61680f5f]:last-child:after {
  width: 17px;
  height: 20px;
  background-image: url("../img/common/common-icon_logout.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-61680f5f]:last-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_logout_sp.svg");
}
}
.btn[data-v-61680f5f] {
  background-color: #19448e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  color: #fff;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.btn[data-v-61680f5f] {
    font-size: 3.65059vw;
}
}
.btn[data-v-61680f5f]:before {
  content: "";
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
.btn[data-v-61680f5f]:before {
    background-size: contain;
}
}
.btn._middle[data-v-61680f5f] {
  width: 285px;
  height: 56px;
}
.btn._small[data-v-61680f5f] {
  width: 165px;
  height: 39px;
}
@media screen and (max-width: 768px) {
.btn._small[data-v-61680f5f] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._small[data-v-61680f5f]:before {
  content: none;
}
.btn._bold[data-v-61680f5f] {
  width: 228px;
  height: 67px;
}
@media screen and (max-width: 768px) {
.btn._bold[data-v-61680f5f] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._large[data-v-61680f5f] {
  width: 854px;
  height: 67px;
}
@media screen and (max-width: 1080px) {
.btn._large[data-v-61680f5f] {
    width: 79vw;
}
}
@media screen and (max-width: 768px) {
.btn._large[data-v-61680f5f] {
    width: 84.74576vw;
    height: 11.73403vw;
}
}
.btn._sticky[data-v-61680f5f] {
  width: 230px;
  height: 37px;
  font-size: 14px;
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-61680f5f] {
    width: 20vw;
    font-size: 1.4vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-61680f5f] {
    width: 55.54107vw;
    height: 10.43025vw;
    font-size: 4.1721vw;
    margin-left: auto;
    margin-right: auto;
}
}
.btn._sticky[data-v-61680f5f]:before {
  width: 15px;
  height: 16px;
  background-image: url("../img/common/common-icon_post.svg");
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-61680f5f]:before {
    width: 1.5vw;
    height: 1.6vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-61680f5f]:before {
    width: 3.25945vw;
    height: 3.52021vw;
}
}
.btn._done[data-v-61680f5f]:before {
  background-image: url("../img/common/common-icon_deal.svg");
  width: 18px;
  height: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-61680f5f]:before {
    width: 3.65059vw;
    height: 2.86832vw;
}
}
.btn._message[data-v-61680f5f]:before {
  width: 22px;
  height: 14px;
  background-image: url("../img/common/common-icon_inquiry.svg");
}
@media screen and (max-width: 768px) {
.btn._message[data-v-61680f5f]:before {
    width: 4.69361vw;
    height: 2.9987vw;
}
}
.btn._message[data-v-61680f5f], .btn._done[data-v-61680f5f] {
  width: 153px;
  height: 44px;
  margin-top: 12px;
}
@media screen and (max-width: 768px) {
.btn._message[data-v-61680f5f], .btn._done[data-v-61680f5f] {
    width: 45.63233vw;
    height: 11.73403vw;
    margin-top: 3.91134vw;
}
}
.btn._done[data-v-61680f5f], .btn._message[data-v-61680f5f] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-61680f5f], .btn._message[data-v-61680f5f] {
    font-size: 3.65059vw;
}
}
.btn[data-v-61680f5f]:hover {
  opacity: 0.7;
}
.links[data-v-61680f5f] {
  margin-bottom: 51px;
}
@media screen and (max-width: 768px) {
.links[data-v-61680f5f] {
    margin-bottom: 3.91134vw;
}
}
.links ul[data-v-61680f5f] {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.links ul[data-v-61680f5f] {
    flex-flow: row wrap;
}
}
.links .link[data-v-61680f5f] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.links .link[data-v-61680f5f] {
    font-size: 3.12907vw;
    margin-bottom: 3.91134vw;
}
}
.links .link > a[data-v-61680f5f] {
  color: #19448e;
}
.links .link > a[data-v-61680f5f],
.links .link > p[data-v-61680f5f] {
  /* line-height: 1; */
}
.links .link[data-v-61680f5f]:after {
  content: "/";
  margin: 0 1rem;
}
.links .link[data-v-61680f5f]:last-child:after {
  content: none;
}
.sidebar[data-v-61680f5f] {
  background-color: #f7f7f7;
  min-height: 1240px;
}
@media screen and (max-width: 768px) {
.sidebar[data-v-61680f5f] {
    position: fixed;
    top: 15.64537vw;
    right: 0;
    bottom: 0;
    z-index: 30;
    width: 72vw;
    min-height: auto;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    visibility: hidden;
    transition: all 0.5s ease;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
}
.sidebar[data-v-61680f5f] ::-webkit-scrollbar {
  display: none;
}
.sidebar._open[data-v-61680f5f] {
  transition: all 0.5s ease;
  visibility: visible;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.sidebar-inner[data-v-61680f5f] {
  margin-top: 45px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
.sidebar-inner[data-v-61680f5f] {
    margin-top: 9.77836vw;
    margin-bottom: 23.46806vw;
}
}
.sidebar-top[data-v-61680f5f] {
  padding: 0 10px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
.sidebar-top[data-v-61680f5f] {
    padding: 0 3.91134vw;
    margin-bottom: 6.5189vw;
}
}
.sidebar-top .btn._sticky[data-v-61680f5f] {
  margin-left: 0;
}
.sidebar-usrname[data-v-61680f5f] {
  font-size: 16px;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
.sidebar-usrname[data-v-61680f5f] {
    font-size: 3.65059vw;
    margin-bottom: 2.60756vw;
}
}
.sidebar-link[data-v-61680f5f] {
  border-top: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
  margin-top: -1px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
.sidebar-link[data-v-61680f5f] {
    font-size: 3.25945vw;
}
}
.sidebar-link > a[data-v-61680f5f] {
  padding: 20px 10px;
  display: block;
}
@media screen and (max-width: 768px) {
.sidebar-link > a[data-v-61680f5f] {
    padding: 4.56323vw;
}
}
.sidebar-link > a[data-v-61680f5f]:hover {
  text-decoration: none;
  opacity: 1;
}
.sidebar-link._current[data-v-61680f5f] {
  background-color: #fff;
  border-color: #fff;
  z-index: 2;
}
@media screen and (max-width: 768px) {
.sidebar-link._current[data-v-61680f5f] {
    background-color: #f7f7f7;
    border-color: #a0a0a0;
}
}
.sidebar-link._current > a[data-v-61680f5f] {
  color: #170613;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
.sidebar-link._current > a[data-v-61680f5f] {
    color: #19448e;
}
}
.sidebar-link:not(._current) > a[data-v-61680f5f] {
  color: #19448e;
}
.sidebar-link[data-v-61680f5f]:not(._current):hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.sidebar-link._logout a[data-v-61680f5f] {
  color: #ffa320;
  display: flex;
  align-items: center;
}
.sidebar-link._logout a[data-v-61680f5f]:before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 1rem;
  width: 3.25945vw;
  height: 3.12907vw;
  background-image: url("../img/common/common-icon_logout.svg");
}
#sidebarCloser[data-v-61680f5f] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  opacity: 0;
  display: none;
}
#sidebarCloser._show[data-v-61680f5f] {
  display: block;
}
.tab-links[data-v-61680f5f] {
  display: flex;
}
.tab-link[data-v-61680f5f] {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-weight: bold;
  border-top: 4px solid;
}
@media screen and (max-width: 768px) {
.tab-link[data-v-61680f5f] {
    font-size: 3.65059vw;
}
}
@media screen and (max-width: 380px) {
.tab-link[data-v-61680f5f] {
    border-width: 2px;
}
}
.tab-link > a[data-v-61680f5f] {
  color: #170613;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  line-height: 1.3;
}
@media screen and (max-width: 768px) {
.tab-link > a[data-v-61680f5f] {
    height: 19.29596vw;
    position: relative;
}
}
.tab-link > a[data-v-61680f5f]:after {
  content: "";
  display: block;
  width: 11px;
  height: 6px;
  background-image: url("../img/common/common-arrow_bottom.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
.tab-link > a[data-v-61680f5f]:after {
    background-image: url("../img/common/common-arrow_bottom_sp.svg");
    width: 3.91134vw;
    height: 2.34681vw;
    position: absolute;
    bottom: 0.91265vw;
    right: 0;
    left: 0;
    margin: auto;
}
}
.tab-link > a[data-v-61680f5f]:hover {
  text-decoration: none;
  opacity: 1;
}
.tab-link.yellow[data-v-61680f5f] {
  border-color: #ffa320;
}
.tab-link.green[data-v-61680f5f] {
  border-color: #00bf16;
}
.tab-link.red[data-v-61680f5f] {
  border-color: #cc1458;
}
.tab-link._current[data-v-61680f5f] {
  background-color: #f7f7f7;
  pointer-events: none;
}
.tab-link[data-v-61680f5f]:not(._current) {
  opacity: 0.3;
}
.tab-link[data-v-61680f5f]:not(._current):hover {
  background-color: #f7f7f7;
  opacity: 1;
}
.sell[data-v-61680f5f] {
  display: none;
}
.sell._current[data-v-61680f5f] {
  display: block;
}
.sell-body[data-v-61680f5f] {
  padding: 0 55px;
  min-height: 1170px;
}
@media screen and (max-width: 768px) {
.sell-body[data-v-61680f5f] {
    padding: 0 6.5189vw 6.5189vw 6.5189vw;
    min-height: 78.22686vw;
}
}
.sell-body._current[data-v-61680f5f] {
  display: block;
}
.sell-body[data-v-61680f5f]:not(._current) {
  display: none;
}
.buy[data-v-61680f5f] {
  display: none;
}
.buy._current[data-v-61680f5f] {
  display: block;
}
.buy-body[data-v-61680f5f] {
  padding: 0 55px;
  min-height: 1170px;
}
@media screen and (max-width: 768px) {
.buy-body[data-v-61680f5f] {
    padding: 0 6.5189vw 6.5189vw 6.5189vw;
    min-height: 78.22686vw;
}
}
.buy-body._current[data-v-61680f5f] {
  display: block;
}
.buy-body[data-v-61680f5f]:not(._current) {
  display: none;
}
.message[data-v-61680f5f] {
  display: none;
}
.message._current[data-v-61680f5f] {
  display: block;
}
.message-body[data-v-61680f5f] {
  padding: 0 55px;
  min-height: 1170px;
}
@media screen and (max-width: 768px) {
.message-body[data-v-61680f5f] {
    padding: 0 6.5189vw 6.5189vw 6.5189vw;
    min-height: 78.22686vw;
}
}
.message-body._current[data-v-61680f5f] {
  display: block;
}
.message-body[data-v-61680f5f]:not(._current) {
  display: none;
}
.like[data-v-61680f5f] {
  display: none;
}
.like._current[data-v-61680f5f] {
  display: block;
}
.like-list[data-v-61680f5f] {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
}
@media screen and (max-width: 1330px) {
.like-list[data-v-61680f5f] {
    -ms-grid-columns: 33% 34% 33%;
        grid-template-columns: 33% 34% 33%;
}
}
@media screen and (max-width: 768px) {
.like-list[data-v-61680f5f] {
    -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
    padding-left: 6.5189vw;
    padding-right: 6.5189vw;
}
}
.setting[data-v-61680f5f] {
  display: none;
}
.setting._current[data-v-61680f5f] {
  display: block;
}
.setting-links[data-v-61680f5f] {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
.setting-links[data-v-61680f5f] {
    margin-top: 11.73403vw;
    padding-left: 6.5189vw;
    padding-right: 6.5189vw;
}
}
.setting-link[data-v-61680f5f] {
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.setting-link[data-v-61680f5f] {
    font-size: 3.12907vw;
}
}
.setting-link a[data-v-61680f5f] {
  color: #19448e;
}
.setting-link[data-v-61680f5f]:first-child {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
.setting-link[data-v-61680f5f]:first-child {
    margin-bottom: 9.77836vw;
}
}
.corporate[data-v-61680f5f] {
  display: none;
}
.corporate._current[data-v-61680f5f] {
  display: block;
}
.item[data-v-61680f5f] {
  display: flex;
  padding: 35px 0;
  border-bottom: 1px solid #a0a0a0;
}
@media screen and (max-width: 768px) {
.item[data-v-61680f5f] {
    padding: 6.5189vw 0;
}
}
.item-col[data-v-61680f5f] {
  flex-flow: column;
}
.item-box[data-v-61680f5f] {
  display: flex;
}
.item-img[data-v-61680f5f] {
  width: 156px;
  height: 208px;
  margin-right: 35px;
}
@media screen and (max-width: 768px) {
.item-img[data-v-61680f5f] {
    width: 20.33898vw;
    height: 27.11864vw;
    margin-right: 5.21512vw;
    flex-shrink: 0;
}
}
.item-img img[data-v-61680f5f] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.item-name[data-v-61680f5f] {
  font-size: 18px;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
.item-name[data-v-61680f5f] {
    font-size: 4.1721vw;
    margin-bottom: 1.56454vw;
}
}
.item-price[data-v-61680f5f] {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
.item-price[data-v-61680f5f] {
    font-size: 3.38983vw;
    margin-bottom: 1.56454vw;
}
}
.item-info[data-v-61680f5f] {
  display: flex;
  flex-flow: column nowrap;
}
.item-post[data-v-61680f5f], .item-period[data-v-61680f5f], .item-buyer[data-v-61680f5f], .item-date[data-v-61680f5f], .item-like[data-v-61680f5f] {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
.item-post[data-v-61680f5f], .item-period[data-v-61680f5f], .item-buyer[data-v-61680f5f], .item-date[data-v-61680f5f], .item-like[data-v-61680f5f] {
    margin-bottom: 2.60756vw;
}
}
.item-name[data-v-61680f5f], .item-edit[data-v-61680f5f], .item-relist[data-v-61680f5f] {
  font-size: 18px;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.item-name[data-v-61680f5f], .item-edit[data-v-61680f5f], .item-relist[data-v-61680f5f] {
    font-size: 3.65059vw;
}
}
.item-edit[data-v-61680f5f], .item-relist[data-v-61680f5f] {
  color: #19448e;
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
.item-edit[data-v-61680f5f], .item-relist[data-v-61680f5f] {
    margin-top: 1.04302vw;
}
}
.item-edit[data-v-61680f5f]:before, .item-relist[data-v-61680f5f]:before {
  content: "";
  display: block;
  margin-right: 0.7rem;
  background-repeat: no-repeat;
  background-size: contain;
}
.item-edit[data-v-61680f5f]:before {
  width: 21px;
  height: 21px;
  background-image: url("../img/common/common-icon_edit.svg");
}
@media screen and (max-width: 768px) {
.item-edit[data-v-61680f5f]:before {
    width: 3.65059vw;
    height: 3.65059vw;
}
}
.item-relist[data-v-61680f5f]:before {
  width: 17px;
  height: 19px;
  background-image: url("../img/common/common-icon_post.svg");
}
@media screen and (max-width: 768px) {
.item-relist[data-v-61680f5f]:before {
    width: 3.25945vw;
    height: 3.52021vw;
}
}
.item-seller[data-v-61680f5f] {
  margin-bottom: 10px;
}
.item-seller + a[data-v-61680f5f] {
  color: #19448e;
  font-weight: 600;
  font-weight: bold;
}
.item-latest[data-v-61680f5f] {
  color: #7b6e77;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
.item-latest[data-v-61680f5f] {
    margin-top: 2.60756vw;
}
}
.item-part[data-v-61680f5f] {
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.item-part[data-v-61680f5f] {
    font-size: 3.38983vw;
}
}
.item-part *[data-v-61680f5f] {
  line-height: 1.4;
}
.item-part.usr[data-v-61680f5f] {
  background-color: #fff;
  padding: 10px 20px;
  margin: 15px 0;
}
@media screen and (max-width: 768px) {
.item-part.usr[data-v-61680f5f] {
    padding: 1.82529vw 2.60756vw;
    margin: 2.60756vw 0;
    flex-flow: column;
}
}
.item-part.usr a[data-v-61680f5f] {
  color: #19448e;
}
.item ._red[data-v-61680f5f],
.litem ._red[data-v-61680f5f],
.item-box ._red[data-v-61680f5f] {
  color: #cc1458;
}
.litem[data-v-61680f5f] {
  display: inline-flex;
  flex-flow: column;
  margin: 0 auto 45px auto;
}
@media screen and (max-width: 768px) {
.litem[data-v-61680f5f] {
    margin-bottom: 7.82269vw;
}
}
.litem-link[data-v-61680f5f] {
  color: #170613;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
.litem-link[data-v-61680f5f] {
    margin-bottom: 2.60756vw;
}
}
.litem-link[data-v-61680f5f]:hover {
  text-decoration: none;
}
.litem-img[data-v-61680f5f] {
  width: 235px;
  height: 313px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1081px) {
.litem-img[data-v-61680f5f] {
    width: 21vw;
    height: 29vw;
}
}
@media screen and (max-width: 768px) {
.litem-img[data-v-61680f5f] {
    width: 40.41721vw;
    height: 53.84615vw;
    margin-bottom: 3.91134vw;
}
}
.litem-img img[data-v-61680f5f] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.litem-name[data-v-61680f5f] {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
.litem-name[data-v-61680f5f] {
    font-size: 3.65059vw;
    margin-bottom: 1.30378vw;
}
}
.litem-price[data-v-61680f5f] {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
.litem-price[data-v-61680f5f] {
    font-size: 3.25945vw;
}
}
.litem-delete[data-v-61680f5f] {
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
  margin-top: 0;
  color: #19448e;
  display: inline-flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
@media screen and (max-width: 768px) {
.litem-delete[data-v-61680f5f] {
    font-size: 3.65059vw;
}
}
.litem-delete[data-v-61680f5f]:before {
  content: "";
  display: block;
  margin-right: 0.7rem;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  background-image: url("../img/common/common-icon_delete.svg");
}
@media screen and (max-width: 768px) {
.litem-delete[data-v-61680f5f]:before {
    width: 3.12907vw;
    height: 3.25945vw;
}
}
.label[data-v-61680f5f] {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 38px 0;
  border-bottom: 1px solid #a0a0a0;
}
@media screen and (max-width: 768px) {
.label[data-v-61680f5f] {
    padding: 5.86701vw 0 4.95437vw 0;
    flex-flow: column;
    align-items: flex-start;
    font-size: 3.12907vw;
}
}
@media screen and (max-width: 768px) {
.label-txt[data-v-61680f5f] {
    margin-bottom: 2.60756vw;
}
}
.label-list[data-v-61680f5f] {
  display: flex;
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
.label-list[data-v-61680f5f] {
    margin-left: auto;
    margin-right: auto;
}
}
.label-item[data-v-61680f5f] {
  font-weight: 600;
  font-weight: bold;
}
.label-item[data-v-61680f5f]:first-child {
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
.label-item[data-v-61680f5f]:first-child {
    margin-right: 1.30378vw;
}
}
.label-item a[data-v-61680f5f] {
  width: 126px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.label-item a[data-v-61680f5f] {
    width: 41.72099vw;
    height: 9.38722vw;
}
}
@media screen and (max-width: 400px) {
.label-item a[data-v-61680f5f] {
    border-width: 1px;
}
}
.label-item a[data-v-61680f5f]:hover {
  text-decoration: none;
}
.label-item.dealing a[data-v-61680f5f] {
  color: #00bf28;
  border-color: #00bf28;
}
.label-item.done a[data-v-61680f5f] {
  color: #cc1458;
  border-color: #cc1458;
}
.label-item._active[data-v-61680f5f] {
  pointer-events: none;
}
.label-item._active.dealing a[data-v-61680f5f], .label-item._active.done a[data-v-61680f5f] {
  color: #fff;
}
.label-item._active.dealing a[data-v-61680f5f] {
  background-color: #00bf28;
}
.label-item._active.done a[data-v-61680f5f] {
  background-color: #cc1458;
}
.user[data-v-61680f5f] {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
.user[data-v-61680f5f] {
    margin-bottom: 3.25945vw;
}
}
.user *[data-v-61680f5f] {
  line-height: 1;
}
.user-label[data-v-61680f5f] {
  font-size: 10px;
  padding: 5px;
  color: #fff;
}
@media screen and (max-width: 768px) {
.user-label[data-v-61680f5f] {
    font-size: 2.60756vw;
    padding: 1.30378vw 1.95567vw;
}
}
.user-label.dealing[data-v-61680f5f] {
  background-color: #00bf28;
}
.user-label.done[data-v-61680f5f] {
  background-color: #cc1458;
}
.user-name[data-v-61680f5f] {
  font-size: 18px;
  margin-left: 10px;
  color: #19448e;
}
@media screen and (max-width: 768px) {
.user-name[data-v-61680f5f] {
    font-size: 4.1721vw;
    margin-left: 2.86832vw;
}
}
.form[data-v-61680f5f] {
  font-size: 14px;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
.form[data-v-61680f5f] {
    font-size: 3.25945vw;
    padding-right: 6.5189vw;
    padding-left: 6.5189vw;
}
}
.form-item[data-v-61680f5f] {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}
@media screen and (max-width: 768px) {
.form-item[data-v-61680f5f] {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 3.25945vw;
}
}
.form-item label[data-v-61680f5f],
.form-item .grouptit[data-v-61680f5f] {
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
.form-item label[data-v-61680f5f],
  .form-item .grouptit[data-v-61680f5f] {
    margin-right: 0;
    margin-bottom: 1.04302vw;
}
}
.form-item label[data-v-61680f5f]:not(.closed),
.form-item .grouptit[data-v-61680f5f]:not(.closed) {
  font-weight: 600;
  font-weight: bold;
  width: 220px;
  padding: 15px 18px;
  background-color: #f7f7f7;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
.form-item label[data-v-61680f5f]:not(.closed),
  .form-item .grouptit[data-v-61680f5f]:not(.closed) {
    width: 100%;
    padding: 3.25945vw 3.91134vw;
}
}
.form-item label span[data-v-61680f5f],
.form-item .grouptit span[data-v-61680f5f] {
  font-weight: 400;
}
@media screen and (max-width: 768px) {
.form-item .grouptit[data-v-61680f5f] {
    margin-bottom: 4.56323vw;
}
}
.form-item input[data-v-61680f5f],
.form-item select[data-v-61680f5f],
.form-item textarea[data-v-61680f5f] {
  padding: 15px;
  border: 1px solid #19448e;
  width: 330px;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-61680f5f],
  .form-item select[data-v-61680f5f],
  .form-item textarea[data-v-61680f5f] {
    padding: 3.25945vw;
    width: 100%;
    font-size: 3.25945vw;
}
}
.form-item input._full[data-v-61680f5f],
.form-item select._full[data-v-61680f5f],
.form-item textarea._full[data-v-61680f5f] {
  width: 100%;
}
.form-item input._small[data-v-61680f5f],
.form-item select._small[data-v-61680f5f],
.form-item textarea._small[data-v-61680f5f] {
  width: 110px;
}
@media screen and (max-width: 768px) {
.form-item input._small[data-v-61680f5f],
  .form-item select._small[data-v-61680f5f],
  .form-item textarea._small[data-v-61680f5f] {
    width: 22.16428vw;
}
}
.form-item input._medium[data-v-61680f5f],
.form-item select._medium[data-v-61680f5f],
.form-item textarea._medium[data-v-61680f5f] {
  width: 234px;
}
@media screen and (max-width: 768px) {
.form-item input._medium[data-v-61680f5f],
  .form-item select._medium[data-v-61680f5f],
  .form-item textarea._medium[data-v-61680f5f] {
    width: 100%;
}
}
.form-item input[data-v-61680f5f]:focus,
.form-item select[data-v-61680f5f]:focus,
.form-item textarea[data-v-61680f5f]:focus {
  outline: none;
}
.form-item label[data-v-61680f5f],
.form-item input[data-v-61680f5f],
.form-item textarea[data-v-61680f5f] {
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.form-item input[data-v-61680f5f] {
  height: inherit;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-61680f5f] {
    height: 10.43025vw;
}
}
.form-item input[data-v-61680f5f],
.form-item textarea[data-v-61680f5f] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-61680f5f],
  .form-item textarea[data-v-61680f5f] {
    font-size: 3.12907vw;
}
}
.form-item select[data-v-61680f5f] {
  height: 100%;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.form-item select[data-v-61680f5f] {
    background-image: url("../img/common/common-arrowblue_bottom.svg");
    background-repeat: no-repeat;
    background-size: 3.65059vw 1.95567vw;
    background-position: right 3.25945vw center;
}
}
.form-item.submit[data-v-61680f5f] {
  margin-top: 40px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
.form-item.submit[data-v-61680f5f] {
    margin-top: 6.77966vw;
}
}
@media screen and (max-width: 768px) {
.form-item.submit button[data-v-61680f5f] {
    width: 100%;
}
}
.form-item.submit button[data-v-61680f5f]:before {
  width: 21px;
  height: 21px;
  background-image: url("../img/common/common-icon_edit.svg");
}
@media screen and (max-width: 768px) {
.form-item.submit button[data-v-61680f5f]:before {
    width: 3.65059vw;
    height: 3.65059vw;
    margin-right: 2.34681vw;
}
}
.form-item fieldset[data-v-61680f5f] {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.form-item fieldset[data-v-61680f5f] {
    flex-flow: column;
}
}
.form-item fieldset input[type="checkbox"][data-v-61680f5f] {
  display: none;
}
.form-item fieldset input[type="checkbox"]:checked + .closed[data-v-61680f5f]:before {
  background-image: url("../img/common/common-icon_check.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}
.form-item fieldset .closed[data-v-61680f5f] {
  display: flex;
  align-items: center;
  line-height: 1;
}
@media screen and (max-width: 768px) {
.form-item fieldset .closed[data-v-61680f5f] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
}
.form-item fieldset .closed[data-v-61680f5f]:before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #19448e;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-item fieldset .closed[data-v-61680f5f]:before {
    width: 3.65059vw;
    height: 3.65059vw;
    margin-right: 2.60756vw;
}
}
@media screen and (max-width: 768px) {
.form-item fieldset .form-cnt[data-v-61680f5f] {
    flex-flow: row wrap;
}
}
@media screen and (max-width: 768px) {
.form-item fieldset .form-cnt .form-part[data-v-61680f5f] {
    width: 33%;
    margin-bottom: 7.82269vw;
}
}
.form-cnt[data-v-61680f5f] {
  display: flex;
  align-items: flex-end;
}
.form-cnt.col[data-v-61680f5f] {
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
}
.form-cnt.col .form-icon[data-v-61680f5f] {
  margin: 0 0 5px 0 !important;
}
@media screen and (max-width: 768px) {
.form-cnt.col .form-icon[data-v-61680f5f] {
    margin-bottom: 0.91265vw;
}
}
.form-cnt.time[data-v-61680f5f] {
  align-items: center;
  flex-flow: row nowrap;
}
.form-cnt.time input[data-v-61680f5f] {
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.form-cnt.time input[data-v-61680f5f] {
    min-width: 18.25293vw;
    padding: 3.25945vw 0 3.25945vw 3.25945vw;
}
}
.form-cnt.time span[data-v-61680f5f] {
  margin: 0 10px;
}
@media screen and (max-width: 768px) {
.form-cnt.time span[data-v-61680f5f] {
    margin: 0 2.60756vw;
}
}
.form-cnt:not(.col) .form-icon[data-v-61680f5f] {
  height: 100%;
}
.form-cnt:not(.col) .form-icon[data-v-61680f5f]:not(:first-child) {
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
.form-cnt:not(.col) .form-icon[data-v-61680f5f]:not(:first-child) {
    margin: 0 0.65189vw;
}
}
.form-cnt:not(.col) .form-icon[data-v-61680f5f]:first-child {
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-cnt:not(.col) .form-icon[data-v-61680f5f]:first-child {
    margin-right: 0.65189vw;
}
}
.form-icon[data-v-61680f5f] {
  position: relative;
  height: inherit;
}
@media screen and (max-width: 768px) {
.form-icon[data-v-61680f5f] {
    width: 100%;
}
}
.form-icon[data-v-61680f5f]:after {
  content: "";
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 1.5rem;
  width: 11px;
  height: 13px;
  background-image: url("../img/common/common-arrowblue_bottom.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (max-width: 768px) {
.form-icon[data-v-61680f5f]:after {
    content: none;
}
}
.drawer[data-v-61680f5f] {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 768px) {
.drawer[data-v-61680f5f] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(15.6vw - 1.6vw);
    height: calc(15.6vw - 1.6vw);
    background-color: #fff;
}
}
#drawer-checkbox[data-v-61680f5f] {
  display: none;
}
#drawer-icon[data-v-61680f5f] {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 55;
  width: 100%;
  height: 100%;
}
#drawer-icon span[data-v-61680f5f] {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  width: 5.86701vw;
  height: 3px;
  background-color: #19448e;
}
#drawer-icon span[data-v-61680f5f]::before,
#drawer-icon span[data-v-61680f5f]::after {
  -webkit-transform: rotate(0);
  background: #19448e;
  content: "";
  display: block;
  height: 3px;
  margin: auto;
  position: absolute;
  transform: rotate(0);
  transition: all 0.3s ease-in-out;
  width: 100%;
}
#drawer-icon span[data-v-61680f5f]::before {
  top: -1.95567vw;
}
#drawer-icon span[data-v-61680f5f]::after {
  bottom: -1.95567vw;
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f] {
  background: rgba(255, 255, 255, 0);
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::before,
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::after {
  content: "";
  display: block;
  height: 4px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 599px) {
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::before,
  #drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::after {
    height: 2px;
}
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#drawer-checkbox:checked ~ #drawer-icon span[data-v-61680f5f]::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* --------------------------------------------------------
    page：各モジュールの位置の指定、ページごとにパーツのサイズが違う時など
-------------------------------------------------------- */
.main[data-v-61680f5f] {
  display: flex;
}
.main .workspace[data-v-61680f5f] {
  width: 100%;
}
.main .workspace-inner[data-v-61680f5f] {
  margin-top: 45px;
  margin-bottom: 200px;
  padding: 0 50px;
}
@media screen and (max-width: 1081px) {
.main .workspace-inner[data-v-61680f5f] {
    padding: 0 2.5vw;
}
}
@media screen and (max-width: 768px) {
.main .workspace-inner[data-v-61680f5f] {
    padding: 0;
    margin-top: 7.1708vw;
    margin-bottom: 20.59974vw;
}
}
.main .workspace-cnts[data-v-61680f5f] {
  background-color: #f7f7f7;
}
@media screen and (max-width: 768px) {
.main .workspace-top[data-v-61680f5f] {
    margin-left: 6.5189vw;
    margin-right: 6.5189vw;
    margin-bottom: 7.82269vw;
    background-color: #f7f7f7;
    padding: 6.12777vw 2.60756vw;
}
}
@media screen and (max-width: 768px) {
.main .workspace-usrname[data-v-61680f5f] {
    font-size: 3.65059vw;
    font-weight: bold;
    margin-bottom: 2.60756vw;
    line-height: 1.3;
    text-align: center;
}
}
.main .workspace-tit[data-v-61680f5f] {
  font-size: 24px;
  line-height: 1.5;
  font-family: source-han-sans-japanese,  sans-serif;
  font-weight: 600;
  font-weight: bold;
  border-bottom: 1px solid #ffa320;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
.main .workspace-tit[data-v-61680f5f] {
    font-size: 7.43155vw;
    margin-bottom: 9.12647vw;
    margin-left: 6.5189vw;
    margin-right: 6.5189vw;
    border-width: 2px;
    padding-bottom: 3.25945vw;
}
}
@media screen and (max-width: 400px) {
.main .workspace-tit[data-v-61680f5f] {
    border-width: 1px;
}
}
@media screen and (max-width: 768px) {
.main .workspace .links[data-v-61680f5f] {
    padding: 0 6.5189vw;
}
}
span.new-lavel[data-v-61680f5f] {
    font-family: 'source-han-sans-japanese';
    font-size: 14px;
    margin-left: 0.7rem;
    font-weight: 600;
    color: #cc1658;
}
@media screen and (max-width: 768px) {
span.new-lavel[data-v-61680f5f] {
    font-size: 3.38983vw;
    margin-left: 0.5rem;
}
}


/* --------------------------------------------------------
    振込申請ページCSS追加
-------------------------------------------------------- */
.earning-box[data-v-61680f5f]{
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
	background: #F7F7F7 0% 0% no-repeat padding-box;
	padding: 20px;
}
.earning-tit[data-v-61680f5f]{
	text-align: center;
	font: normal normal normal 16px/22px Hiragino Sans;
	letter-spacing: -0.4px;
	color: #170613;
	padding-bottom: 20px;
	border-bottom: 1px solid #A0A0A0;
}
.earning-money[data-v-61680f5f]{
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	letter-spacing: -0.75px;
	color: #170613;
	padding-top: 20px;
}
.transfer-submit button[data-v-61680f5f]{
	margin: 0 auto;
	margin-top: 30px;
	position: relative;
	font-size: 16px;
	padding-top: 20px;
	padding-bottom: 20px;
	width: 285px;
}
.transfer-submit .btn[data-v-61680f5f]:before {
    content: "";
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 1rem;
	width: 30px;
    height: 21px;
    background-image: url(../img/common/common-icon_bank.svg);
    /* background-image: url(../img/common/common-icon_post.svg); */
}
.workspace-subtit[data-v-61680f5f]{
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	margin-top: 95px;
	letter-spacing: -0.6px;
	color: #170613;
	position: relative;
}
.workspace-subtit[data-v-61680f5f]::after{
	position: absolute;
	content: "";
	display: block;
	height: 5px;
	width: 50px;
	background-color: #FFA320;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
    left: 0;
    right: 0;
}
.trdhist[data-v-61680f5f]{
	width: 100%;
	margin-top: 65px;
}
.trdhist tr[data-v-61680f5f]{
	display: flex;
}
.trdhist th[data-v-61680f5f],td[data-v-61680f5f]{
	font-size: 14px;
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
}
.trdhist th[data-v-61680f5f]{
	color: #FFFFFF;
	background-color: #9D9D9D;
}
.trdhist th[data-v-61680f5f]{
	font-size: 14px;
	color: #FFFFFF;
	background-color: #9D9D9D;
	padding-top: 15px;
	padding-bottom: 15px;
}
.trdhist th[data-v-61680f5f]{
	margin-bottom: 5px;
}
.trdhist th[data-v-61680f5f]:nth-child(1),td[data-v-61680f5f]:nth-child(1){
	width: 25%;
}
.trdhist th[data-v-61680f5f]:nth-child(2),td[data-v-61680f5f]:nth-child(2){
	width: 48%;
	margin-left: 5px;
	margin-right: 5px;
}
.trdhist th[data-v-61680f5f]:nth-child(3),td[data-v-61680f5f]:nth-child(3){
	width: 25%;
}
.trdhist td[data-v-61680f5f]{
	background-color: #F7F7F7;
	margin-bottom: 8px;
}
.trdhist[data-v-61680f5f]{
	margin-bottom: 55px;
}
.news-pgnav[data-v-61680f5f]{
	border-top: 1px solid #FFA320;
	padding-top: 50px;
	position: relative;
}
.news-pgnav ._next[data-v-61680f5f],._prev[data-v-61680f5f]{
	position: absolute;
	color: #19448E;
	font-size: 20px;
}
.news-pgnav ._next[data-v-61680f5f]{
	right: 0;
	bottom: 8px;
}
.news-pgnav ._prev[data-v-61680f5f]{
	left: 0;
	bottom: 8px;
}
.news-links[data-v-61680f5f]{
	display: flex;
	justify-content: center;
    align-items: center;
	font-size: 20px;
	color: #19448E;
}
.news-links ._current[data-v-61680f5f]{
	font-weight: bold;
}
@media screen and (max-width: 768px) {
.earning-tit[data-v-61680f5f]{
		font-size: 3.84vw;
}
.earning-box[data-v-61680f5f] {
		padding: 2.67vw;
}
.earning-tit[data-v-61680f5f]{
		padding-bottom: 2.67vw;
		border-bottom: 0.27vw solid #A0A0A0;
}
.earning-money[data-v-61680f5f]{
		padding-top: 2.67vw;
		font-size: 7.25vw;
}
.transfer-submit button[data-v-61680f5f]{
		margin-top: 6vw;
}
.workspace-subtit[data-v-61680f5f]{
		margin-top: 10.67vw;
		font-size: 5.67vw;
}
.workspace-subtit[data-v-61680f5f]::after{
		height: 0.53vw;
}
.trdhist[data-v-61680f5f]{
		margin-top: 10.93vw;
		max-width: 78.67vw;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10vw;
}
.trdhist th[data-v-61680f5f],td[data-v-61680f5f]{
		font-size: 3.36vw;
}
.trdhist th[data-v-61680f5f]:nth-child(2),td[data-v-61680f5f]:nth-child(2){
		margin-left: 0.9vw;
		margin-right: 0.9vw;
}
.trdhist th[data-v-61680f5f]{
		margin-bottom: 2vw;
}
.trdhist td[data-v-61680f5f]{
		margin-bottom: 2vw;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4vw;
}
.news-pgnav[data-v-61680f5f]{
		padding-top: 6.93vw;
}
.news-links[data-v-61680f5f]{
		font-size: 4.9vw;
}
.news-pgnav ._next[data-v-61680f5f],._prev[data-v-61680f5f]{
		display: none;
}
.transfer-submit button[data-v-61680f5f]{
		width: 55.54107vw;
		height: 10.43025vw;
		font-size: 4.1721vw;
		margin-left: auto;
		margin-right: auto;
}
.transfer-submit .btn[data-v-61680f5f]:before{
		width: 5.14vw;
		height: 3.6vw;
}
}

/**
20220315以降_画面デザイン調整
*/
/* マイページの商品画像のデザイン調整 */
.item-img a[data-v-61680f5f]{
    display: inline!important;
}

@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.newitem[data-v-cb270b66] {
	padding-top: 50px;
	padding-bottom: 200px;
}
@media screen and (max-width: 768px) {
.newitem[data-v-cb270b66] {
		padding-top: 6.5vw;
		padding-bottom: 19.5vw;
}
}
.newitem-tit[data-v-cb270b66] {
	font-family: source-han-sans-japanese, sans-serif;
	font-weight: 700;
	font-weight: bold;
	font-size: 38px;
	margin-bottom: 30px;
	padding-bottom: 2rem;
	border-bottom: 1px solid #ffa320;
}
@media screen and (max-width: 1024px) {
.newitem-tit[data-v-cb270b66] {
		font-size: 3.7vw;
}
}
@media screen and (max-width: 768px) {
.newitem-tit[data-v-cb270b66] {
		font-size: 7.4vw;
		margin-bottom: 9vw;
}
}
.newitem .btn[data-v-cb270b66] {
	margin: auto;
}
@media screen and (max-width: 1080px) {
.newitem .btn._large[data-v-cb270b66] {
		width: 79vw;
}
}
@media screen and (max-width: 768px) {
.newitem .btn._large[data-v-cb270b66] {
		width: 84.7vw;
		height: 12vw;
		font-size: 3.6vw;
}
}
.newitem .btn[data-v-cb270b66]:before {
	width: 20px;
	height: 21px;
	background-image: url(../img/common/common-icon_post.svg);
}
@media screen and (max-width: 768px) {
.newitem .btn[data-v-cb270b66]:before {
		width: 3.2vw;
		height: 3.6vw;
}
}
@media screen and (max-width: 768px) {
.newitem .links[data-v-cb270b66] {
		margin-bottom: 4vw;
}
}
@media screen and (max-width: 768px) {
.newitem #imgList[data-v-cb270b66] {
		justify-content: center;
}
}
.form[data-v-cb270b66] {
	max-width: 1200px;
	width: 100%;
	padding: 0 100px;
	margin: auto;
	font-size: 14px;
}
@media screen and (max-width: 1080px) {
.form[data-v-cb270b66] {
		padding: 0 40px;
}
}
@media screen and (max-width: 900px) {
.form[data-v-cb270b66] {
		padding: 0;
}
}
@media screen and (max-width: 768px) {
.form[data-v-cb270b66] {
		font-size: 3.2vw;
}
}
.form-parts[data-v-cb270b66] {
	display: flex;
	flex-flow: row wrap;
	grid-gap: 1.5rem;
	gap: 1.5rem;
}
@media screen and (max-width: 768px) {
.form-parts[data-v-cb270b66] {
		grid-gap: 0;
		gap: 0;
		padding: 4.2vw 0;
}
}
.form-parts .form-part[data-v-cb270b66]:not(:last-child) {
	margin-bottom: 0;
}
@media screen and (max-width: 768px) {
.form-parts .form-part[data-v-cb270b66]:not(:last-child) {
		margin-right: 15%;
		margin-bottom: 6vw;
}
}
.form-part[data-v-cb270b66] {
	display: flex;
	grid-gap: 1%;
	gap: 1%;
}
@media screen and (max-width: 768px) {
.form-part[data-v-cb270b66] {
		flex-flow: column;
		grid-gap: 5px;
		gap: 5px;
}
}
.form-part[data-v-cb270b66]:not(:last-child) {
	margin-bottom: 1.5rem;
}
@media screen and (max-width: 768px) {
.form-part[data-v-cb270b66]:not(:last-child) {
		margin-bottom: 2.6vw;
}
}
.form-part .form-label + *[data-v-cb270b66] {
	flex-grow: 1;
}
.form-cnt[data-v-cb270b66] {
	display: flex;
}
.form-cnt.flex-col select[data-v-cb270b66] {
	height: 44px;
}
@media screen and (max-width: 768px) {
.form-cnt.flex-col select[data-v-cb270b66] {
		height: 10vw;
}
}
.form-cnt.justify-center[data-v-cb270b66] {
	justify-content: center;
}
.form-label[data-v-cb270b66] {
	background-color: #f7f7f7;
	padding: 1.2rem 2rem;
	flex-basis: 20%;
	font-weight: bold;
}
@media screen and (max-width: 1080px) {
.form-label[data-v-cb270b66] {
		flex-basis: 24%;
}
}
@media screen and (max-width: 768px) {
.form-label[data-v-cb270b66] {
		padding: 3.2vw 4vw;
		margin-bottom: 4px;
}
}
.form-label ._sub[data-v-cb270b66] {
	color: #cc1458;
	font-weight: normal;
}
.form-unit[data-v-cb270b66] {
	display: flex;
	align-items: center;
}
.form-unit[data-v-cb270b66]:before {
	content: '';
	width: 18px;
	height: 18px;
	display: inline-block;
	border: 1px solid #19448e;
	margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-unit[data-v-cb270b66]:before {
		width: 3.6vw;
		height: 3.6vw;
		margin-right: 2.5vw;
}
}
.form-icon select[data-v-cb270b66] {
	height: 100%;
	background-size: 13px 11px;
	background-repeat: no-repeat;
	background-position: right 1.5rem center;
	background-image: url(../img/common/common-arrowblue_bottom.svg);
}
@media screen and (max-width: 768px) {
.form-icon select[data-v-cb270b66] {
		background-size: 3.6vw 2vw;
		background-position: right 4vw center;
}
}
.form-item-flex[data-v-cb270b66] {
	display: flex;
}
.form-item.align-bottom[data-v-cb270b66] {
	align-items: flex-end;
}
.form-imgs[data-v-cb270b66] {
	display: flex;
	flex-flow: row wrap;
	grid-gap: 5px;
	gap: 5px;
}
@media screen and (max-width: 768px) {
.form-imgs[data-v-cb270b66] {
		justify-content: space-evenly;
}
}
.form-img[data-v-cb270b66] {
	flex-basis: 70px;
}
@media screen and (max-width: 768px) {
.form-img[data-v-cb270b66] {
		flex-basis: 13vw;
}
}
.form-img label[data-v-cb270b66] {
	display: block;
	cursor: pointer;
	width: 100%;
	height: 92px;
}
@media screen and (max-width: 768px) {
.form-img label[data-v-cb270b66] {
		height: 18vw;
}
}
.form-img label img[data-v-cb270b66] {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.form input[data-v-cb270b66],
.form select[data-v-cb270b66],
.form textarea[data-v-cb270b66] {
	border: 1px solid #19448e;
	border-radius: 0;
	padding-left: 1em;
	padding-right: 1em;
}
.form input[data-v-cb270b66],
.form select[data-v-cb270b66],
.form textarea[data-v-cb270b66],
.form label[data-v-cb270b66] {
	font-size: 14px;
}
@media screen and (max-width: 768px) {
.form input[data-v-cb270b66],
	.form select[data-v-cb270b66],
	.form textarea[data-v-cb270b66],
	.form label[data-v-cb270b66] {
		font-size: 3.2vw;
}
}
.form textarea[data-v-cb270b66] {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}
@media screen and (max-width: 768px) {
.form select[data-v-cb270b66] {
		min-height: 10vw;
}
}
.form input[type='file'][data-v-cb270b66] {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px);
	visibility: hidden;
}
.form input[type='file']:focus + label.upload[data-v-cb270b66],
.form input[type='file']:focus-within + label.upload[data-v-cb270b66] {
	outline: thin dotted;
}
.form input[type='text'][data-v-cb270b66],
.form input[type='number'][data-v-cb270b66] {
	min-height: 44px;
}
@media screen and (max-width: 768px) {
.form input[type='text'][data-v-cb270b66],
	.form input[type='number'][data-v-cb270b66] {
		min-height: 10vw;
}
}
.form input[type='checkbox'][data-v-cb270b66],
.form input[type='radio'][data-v-cb270b66] {
	display: none;
}
.form input[type='checkbox']:checked + .form-unit[data-v-cb270b66]:before,
.form input[type='radio']:checked + .form-unit[data-v-cb270b66]:before {
	background-image: url(../img/common/common-icon_check.svg);
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
}
.form .w-middle[data-v-cb270b66] {
	width: 330px;
}
@media screen and (max-width: 768px) {
.form .w-middle[data-v-cb270b66] {
		width: 100%;
}
}
.form .w-small[data-v-cb270b66] {
	width: 165px;
}
@media screen and (max-width: 768px) {
.form .w-small[data-v-cb270b66] {
		width: 34vw;
}
}
.form .flex-col[data-v-cb270b66] {
	flex-flow: column;
}
.form .flex-col > *[data-v-cb270b66]:not(:last-child) {
	margin-bottom: 5px;
}
.form .flex-row[data-v-cb270b66] {
	flex-flow: row;
	align-items: flex-end;
}
.form .flex-row input[data-v-cb270b66]:not([type='file']),
.form .flex-row select[data-v-cb270b66] {
	height: 100%;
}
@media screen and (max-width: 768px) {
.form .flex-row input[data-v-cb270b66]:not([type='file']),
	.form .flex-row select[data-v-cb270b66] {
		min-height: 10vw;
}
}
.form .gap-5[data-v-cb270b66] {
	grid-gap: 5px;
	gap: 5px;
}
.form-txt[data-v-cb270b66] {
	padding: 25px 0 40px 0;
}
@media screen and (max-width: 768px) {
.form-txt[data-v-cb270b66] {
		padding: 9vw 0 6.5vw 0;
}
}
.form-txt a[data-v-cb270b66] {
	font-weight: bold;
	color: #19448e;
}
.form-attention[data-v-cb270b66] {
	display: inline-block;
	font-size: 12px;
	color: #7b6e77;
}
@media screen and (max-width: 768px) {
.form-attention[data-v-cb270b66] {
		font-size: 2.8vw;
}
}
.form-attention ._bold[data-v-cb270b66] {
	font-weight: bold;
}
.form-attention[data-v-cb270b66]:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background-image: url(../img/common/common-icon_alert.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-attention[data-v-cb270b66]:before {
		width: 3vw;
		height: 3vw;
}
}
@media screen and (max-width: 768px) {
.form .sp-spacer[data-v-cb270b66] {
		padding: 4vw 0;
}
}
@media screen and (max-width: 768px) {
.form .sp-spacer > *[data-v-cb270b66]:not(:last-child) {
		margin-bottom: 4vw;
}
}

@charset "UTF-8";
/* --------------------------------------------------------
    setting
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
/* --------------------------------------------------------
    mixin
-------------------------------------------------------- */
/* --------------------------------------------------------
    pulgins：jQueryで使用するcssなどを配置
-------------------------------------------------------- */
/* --------------------------------------------------------
    module：各ページ使用されるパーツ(ブログ記事、リストなど)
-------------------------------------------------------- */
.header-btn[data-v-cb270b66]:first-child:after {
  width: 21px;
  height: 20px;
  background-image: url("../img/common/common-icon_logout.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-cb270b66]:first-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_logout_sp.svg");
}
}
.header-btn[data-v-cb270b66]:last-child:after {
  width: 17px;
  height: 20px;
  background-image: url("../img/common/common-icon_mypage.svg");
}
@media screen and (max-width: 768px) {
.header-btn[data-v-cb270b66]:last-child:after {
    width: 11.21252vw;
    height: 10.16949vw;
    background-image: url("../img/common/common-icon_mypage_sp.svg");
}
}
.btn[data-v-cb270b66] {
  background-color: #19448e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  color: #fff;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.btn[data-v-cb270b66] {
    font-size: 3.65059vw;
}
}
.btn[data-v-cb270b66]:before {
  content: "";
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
.btn[data-v-cb270b66]:before {
    background-size: contain;
}
}
.btn._middle[data-v-cb270b66] {
  width: 285px;
  height: 56px;
}
.btn._small[data-v-cb270b66] {
  width: 165px;
  height: 39px;
}
@media screen and (max-width: 768px) {
.btn._small[data-v-cb270b66] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._small[data-v-cb270b66]:before {
  content: none;
}
.btn._bold[data-v-cb270b66] {
  width: 228px;
  height: 67px;
}
@media screen and (max-width: 768px) {
.btn._bold[data-v-cb270b66] {
    width: 45.63233vw;
    height: 11.73403vw;
}
}
.btn._large[data-v-cb270b66] {
  width: 854px;
  height: 67px;
}
@media screen and (max-width: 1080px) {
.btn._large[data-v-cb270b66] {
    width: 79vw;
}
}
@media screen and (max-width: 768px) {
.btn._large[data-v-cb270b66] {
    width: 84.74576vw;
    height: 11.73403vw;
}
}
.btn._sticky[data-v-cb270b66] {
  width: 230px;
  height: 37px;
  font-size: 14px;
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-cb270b66] {
    width: 20vw;
    font-size: 1.4vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-cb270b66] {
    width: 55.54107vw;
    height: 10.43025vw;
    font-size: 4.1721vw;
    margin-left: auto;
    margin-right: auto;
}
}
.btn._sticky[data-v-cb270b66]:before {
  width: 15px;
  height: 16px;
  background-image: url("../img/common/common-icon_post.svg");
}
@media screen and (max-width: 981px) {
.btn._sticky[data-v-cb270b66]:before {
    width: 1.5vw;
    height: 1.6vw;
}
}
@media screen and (max-width: 768px) {
.btn._sticky[data-v-cb270b66]:before {
    width: 3.25945vw;
    height: 3.52021vw;
}
}
.btn._done[data-v-cb270b66]:before {
  background-image: url("../img/common/common-icon_deal.svg");
  width: 18px;
  height: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-cb270b66]:before {
    width: 3.65059vw;
    height: 2.86832vw;
}
}
.btn._message[data-v-cb270b66]:before {
  width: 22px;
  height: 14px;
  background-image: url("../img/common/common-icon_inquiry.svg");
}
@media screen and (max-width: 768px) {
.btn._message[data-v-cb270b66]:before {
    width: 4.69361vw;
    height: 2.9987vw;
}
}
.btn._message[data-v-cb270b66], .btn._done[data-v-cb270b66] {
  width: 153px;
  height: 44px;
  margin-top: 12px;
}
@media screen and (max-width: 768px) {
.btn._message[data-v-cb270b66], .btn._done[data-v-cb270b66] {
    width: 45.63233vw;
    height: 11.73403vw;
    margin-top: 3.91134vw;
}
}
.btn._done[data-v-cb270b66], .btn._message[data-v-cb270b66] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.btn._done[data-v-cb270b66], .btn._message[data-v-cb270b66] {
    font-size: 3.65059vw;
}
}
.btn[data-v-cb270b66]:hover {
  opacity: 0.7;
}
.links[data-v-cb270b66] {
  margin-bottom: 51px;
}
@media screen and (max-width: 768px) {
.links[data-v-cb270b66] {
    margin-bottom: 3.91134vw;
}
}
.links ul[data-v-cb270b66] {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.links ul[data-v-cb270b66] {
    flex-flow: row wrap;
}
}
.links .link[data-v-cb270b66] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.links .link[data-v-cb270b66] {
    font-size: 3.12907vw;
    margin-bottom: 3.91134vw;
}
}
.links .link > a[data-v-cb270b66] {
  color: #19448e;
}
.links .link > a[data-v-cb270b66],
.links .link > p[data-v-cb270b66] {
  /* line-height: 1; */
}
.links .link[data-v-cb270b66]:after {
  content: "/";
  margin: 0 1rem;
}
.links .link[data-v-cb270b66]:last-child:after {
  content: none;
}
.form[data-v-cb270b66] {
  font-size: 14px;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
.form[data-v-cb270b66] {
    font-size: 3.25945vw;
    padding-right: 6.5189vw;
    padding-left: 6.5189vw;
}
}
.form-item[data-v-cb270b66] {
  display: flex;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
.form-item[data-v-cb270b66] {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 3.25945vw;
}
}
.form-item label[data-v-cb270b66],
.form-item .grouptit[data-v-cb270b66] {
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
.form-item label[data-v-cb270b66],
  .form-item .grouptit[data-v-cb270b66] {
    margin-right: 0;
    margin-bottom: 1.04302vw;
}
}
.form-item label[data-v-cb270b66]:not(.closed),
.form-item .grouptit[data-v-cb270b66]:not(.closed) {
  font-weight: 600;
  font-weight: bold;
  width: 220px;
  padding: 15px 18px;
  background-color: #f7f7f7;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
.form-item label[data-v-cb270b66]:not(.closed),
  .form-item .grouptit[data-v-cb270b66]:not(.closed) {
    width: 100%;
    padding: 3.25945vw 3.91134vw;
}
}
.form-item label span[data-v-cb270b66],
.form-item .grouptit span[data-v-cb270b66] {
  font-weight: 400;
}
@media screen and (max-width: 768px) {
.form-item .grouptit[data-v-cb270b66] {
    margin-bottom: 4.56323vw;
}
}
.form-item input[data-v-cb270b66],
.form-item select[data-v-cb270b66],
.form-item textarea[data-v-cb270b66] {
  padding: 15px;
  border: 1px solid #19448e;
  width: 330px;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-cb270b66],
  .form-item select[data-v-cb270b66],
  .form-item textarea[data-v-cb270b66] {
    padding: 3.25945vw;
    width: 100%;
    font-size: 3.25945vw;
}
}
.form-item input._full[data-v-cb270b66],
.form-item select._full[data-v-cb270b66],
.form-item textarea._full[data-v-cb270b66] {
  width: 100%;
}
.form-item input._small[data-v-cb270b66],
.form-item select._small[data-v-cb270b66],
.form-item textarea._small[data-v-cb270b66] {
  width: 110px;
}
@media screen and (max-width: 768px) {
.form-item input._small[data-v-cb270b66],
  .form-item select._small[data-v-cb270b66],
  .form-item textarea._small[data-v-cb270b66] {
    width: 22.16428vw;
}
}
.form-item input._medium[data-v-cb270b66],
.form-item select._medium[data-v-cb270b66],
.form-item textarea._medium[data-v-cb270b66] {
  width: 234px;
}
@media screen and (max-width: 768px) {
.form-item input._medium[data-v-cb270b66],
  .form-item select._medium[data-v-cb270b66],
  .form-item textarea._medium[data-v-cb270b66] {
    width: 100%;
}
}
.form-item input[data-v-cb270b66]:focus,
.form-item select[data-v-cb270b66]:focus,
.form-item textarea[data-v-cb270b66]:focus {
  outline: none;
}
.form-item label[data-v-cb270b66],
.form-item input[data-v-cb270b66],
.form-item textarea[data-v-cb270b66] {
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.form-item input[data-v-cb270b66] {
  height: inherit;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-cb270b66] {
    height: 10.43025vw;
}
}
.form-item input[data-v-cb270b66],
.form-item textarea[data-v-cb270b66] {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
.form-item input[data-v-cb270b66],
  .form-item textarea[data-v-cb270b66] {
    font-size: 3.12907vw;
}
}
.form-item select[data-v-cb270b66] {
  height: 100%;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.form-item select[data-v-cb270b66] {
    background-image: url("../img/common/common-arrowblue_bottom.svg");
    background-repeat: no-repeat;
    background-size: 3.65059vw 1.95567vw;
    background-position: right 3.25945vw center;
}
}
.form-item.submit[data-v-cb270b66] {
  margin-top: 40px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
.form-item.submit[data-v-cb270b66] {
    margin-top: 6.77966vw;
}
}
@media screen and (max-width: 768px) {
.form-item.submit button[data-v-cb270b66] {
    width: 100%;
}
}
.form-item.submit button[data-v-cb270b66]:before {
  width: 21px;
  height: 21px;
  background-image: url("../img/common/common-icon_edit.svg");
}
@media screen and (max-width: 768px) {
.form-item.submit button[data-v-cb270b66]:before {
    width: 3.65059vw;
    height: 3.65059vw;
    margin-right: 2.34681vw;
}
}
.form-item fieldset[data-v-cb270b66] {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.form-item fieldset[data-v-cb270b66] {
    flex-flow: column;
}
}
.form-item fieldset input[type="checkbox"][data-v-cb270b66] {
  display: none;
}
.form-item fieldset input[type="checkbox"]:checked + .closed[data-v-cb270b66]:before {
  background-image: url("../img/common/common-icon_check.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}
.form-item fieldset .closed[data-v-cb270b66] {
  display: flex;
  align-items: center;
  line-height: 1;
}
@media screen and (max-width: 768px) {
.form-item fieldset .closed[data-v-cb270b66] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
}
.form-item fieldset .closed[data-v-cb270b66]:before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #19448e;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-item fieldset .closed[data-v-cb270b66]:before {
    width: 3.65059vw;
    height: 3.65059vw;
    margin-right: 2.60756vw;
}
}
@media screen and (max-width: 768px) {
.form-item fieldset .form-cnt[data-v-cb270b66] {
    flex-flow: row wrap;
}
}
@media screen and (max-width: 768px) {
.form-item fieldset .form-cnt .form-part[data-v-cb270b66] {
    width: 33%;
    margin-bottom: 7.82269vw;
}
}
.form-cnt[data-v-cb270b66] {
  display: flex;
  align-items: flex-end;
}
.form-cnt.col[data-v-cb270b66] {
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
}
.form-cnt.col .form-icon[data-v-cb270b66] {
  margin: 0 0 5px 0 !important;
}
@media screen and (max-width: 768px) {
.form-cnt.col .form-icon[data-v-cb270b66] {
    margin-bottom: 0.91265vw;
}
}
.form-cnt.time[data-v-cb270b66] {
  align-items: center;
  flex-flow: row nowrap;
}
.form-cnt.time input[data-v-cb270b66] {
  background-color: #fff;
}
@media screen and (max-width: 768px) {
.form-cnt.time input[data-v-cb270b66] {
    min-width: 18.25293vw;
    padding: 3.25945vw 0 3.25945vw 3.25945vw;
}
}
.form-cnt.time span[data-v-cb270b66] {
  margin: 0 10px;
}
@media screen and (max-width: 768px) {
.form-cnt.time span[data-v-cb270b66] {
    margin: 0 2.60756vw;
}
}
.form-cnt:not(.col) .form-icon[data-v-cb270b66] {
  height: 100%;
}
.form-cnt:not(.col) .form-icon[data-v-cb270b66]:not(:first-child) {
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
.form-cnt:not(.col) .form-icon[data-v-cb270b66]:not(:first-child) {
    margin: 0 0.65189vw;
}
}
.form-cnt:not(.col) .form-icon[data-v-cb270b66]:first-child {
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
.form-cnt:not(.col) .form-icon[data-v-cb270b66]:first-child {
    margin-right: 0.65189vw;
}
}
.form-icon[data-v-cb270b66] {
  position: relative;
  height: inherit;
}
@media screen and (max-width: 768px) {
.form-icon[data-v-cb270b66] {
    width: 100%;
}
}
.form-icon[data-v-cb270b66]:after {
  content: "";
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 1.5rem;
  width: 11px;
  height: 13px;
  background-image: url("../img/common/common-arrowblue_bottom.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (max-width: 768px) {
.form-icon[data-v-cb270b66]:after {
    content: none;
}
}

/* --------------------------------------------------------
    page：各モジュールの位置の指定、ページごとにパーツのサイズが違う時など
-------------------------------------------------------- */
.inquiry[data-v-cb270b66] {
  padding-top: 50px;
  padding-bottom: 160px;
}
@media screen and (max-width: 768px) {
.inquiry[data-v-cb270b66] {
    padding-top: 6.5189vw;
    padding-bottom: 19.55671vw;
}
}
.inquiry-tit[data-v-cb270b66] {
  font-family: source-han-sans-japanese,  sans-serif;
  font-weight: 700;
  font-weight: bold;
  font-size: 38px;
  margin-bottom: 40px;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ffa320;
}
@media screen and (max-width: 1024px) {
.inquiry-tit[data-v-cb270b66] {
    font-size: 3.7vw;
}
}
@media screen and (max-width: 768px) {
.inquiry-tit[data-v-cb270b66] {
    font-size: 7.43155vw;
    margin-bottom: 9.12647vw;
}
}
.inquiry .form[data-v-cb270b66] {
  max-width: 1000px;
  margin: auto;
  padding: 0;
}
.inquiry .form-item[data-v-cb270b66] {
  font-size: 14px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
.inquiry .form-item[data-v-cb270b66] {
    flex-flow: column;
    font-size: 3.12907vw;
    margin-bottom: 11.73403vw;
}
}
.inquiry .form-item[data-v-cb270b66]:last-child {
  flex-flow: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
.inquiry .form-item[data-v-cb270b66]:last-child {
    margin-bottom: 0;
    align-items: flex-start;
}
}
.inquiry .form-item:last-child p[data-v-cb270b66] {
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
.inquiry .form-item:last-child p[data-v-cb270b66] {
    margin-bottom: 5.21512vw;
}
}
.inquiry .form-item:last-child a[data-v-cb270b66] {
  color: #19448e;
  font-weight: 600;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
.inquiry .form-item label[data-v-cb270b66] {
    width: 100%;
    font-size: 3.12907vw;
    padding: 3.25945vw 3.91134vw;
    margin-right: 0;
    margin-bottom: 1rem;
}
}
@media screen and (max-width: 768px) {
.inquiry .form-submit[data-v-cb270b66] {
    width: 100%;
}
}
@media screen and (max-width: 768px) {
.inquiry .form-submit .btn[data-v-cb270b66] {
    width: 100%;
}
}
.inquiry .form-submit .btn[data-v-cb270b66]:before {
  width: 41px;
  height: 20px;
  background-image: url("../img/common/common-icon_send.svg");
}
@media screen and (max-width: 768px) {
.inquiry .form-submit .btn[data-v-cb270b66]:before {
    width: 6.12777vw;
    height: 2.9987vw;
}
}
.register-accept[data-v-cb270b66] {
  display: flex;
  margin-bottom: 40px;
}
.register-accept label[data-v-cb270b66] {
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0;
}
.register-accept label[data-v-cb270b66]:before {
  content: "";
  display: block;
  height: 18px;
  width: 18px;
  border: 1px solid #19448e;
  margin-right: 1rem;
}
.register-accept input[type="checkbox"]:checked + label[data-v-cb270b66]::before {
  background-image: url("../img/common/common-icon_check.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.form-item.terms label[data-v-cb270b66]:not(.closed), .form-item .grouptit[data-v-cb270b66]:not(.closed){
	width: 100%;
}
.form-item.terms label[data-v-cb270b66]:not(.closed), .form-item .grouptit[data-v-cb270b66]:not(.closed){
	background-color: white;
}
@media screen and (max-width: 768px) {
.form-item.terms label[data-v-cb270b66]:not(.closed), .form-item .grouptit[data-v-cb270b66]:not(.closed){
		white-space: nowrap;
		font-size: 2.62907vw;
		padding: 3.25945vw 1vw;
}
.form-item.terms .register-accept[data-v-cb270b66]{
		width: 100%;
}
.inquiry.terms .form-item label[data-v-cb270b66]{
		padding: 3.25945vw 3vw;
}
}